// !: Main.scss

/*
  Style Sheet for UCD Library

  version: 1.0.0
  last modified: 30.06.2021
  Remodeled
*/

/* General stylesheet definitions
********************************/
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.zi-0 {
  z-index: 0 !important;
}

html {
  max-width: 2000px;
  margin: 0 auto;
}
body {
  font-family: 'Roboto Slab', serif;
  font-size: 16px;
  font-weight: 400;

  color: #000000;

  -ms-overflow-style: scrollbar;
}
.s-lib-public-body {
  padding-right: 0;
  padding-left: 0;
}
.is-loading {
  background: #fefefe;
}
.is-loading .message,
.is-loading > * {
  display: none;
}
.thin {
  font-weight: 100;
}
.bold {
  font-weight: 600;
}
.ultra-bold {
  font-weight: 900;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.dropshadow {
  text-shadow: 0 2px 16px rgba(1, 2, 2, 0.7);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 10px 0;

  color: #ffffff;
}

h1 {
  font-size: 2.6em;
}
h2 {
  font-size: 1.5em;

  margin-bottom: 10px;
}
h3 {
  font-size: 1.8em;
}
h4 {
  font-size: 1.6em;
}
h5 {
  font-size: 1.14em;
}
.s-lg-guide-body h5 {
  font-size: 18px;
}
h6 {
  font-size: 14px;
}

a {
  text-decoration: none;

  color: #ffffff;
}
a:hover,
a:focus {
  text-decoration: underline;

  color: #ffffff;
}
a:empty {
  display: none !important;
}

tr + tr.hide-on-siblings {
  display: none;
}

.button {
  display: inline-block;

  width: auto;
  padding: 15px;

  transition: 500ms;
  text-align: center;
  text-decoration: none;

  color: #ffffff;
  border: 0;
  background: #2993d0;
}
.button--border {
  border: 1px solid #ffffff;
  background: transparent;
}
.button--large {
  display: block;

  width: 100%;
  padding: 20px;
}
.ucd-timetable {
  .button--large {
    padding: 13px 20px;
  }
}
.button:hover {
  text-decoration: none;

  opacity: 0.8;
}
.more {
  position: relative;
}
.more::after {
  position: relative;
  top: 1px;

  width: 19px;
  height: 12px;
  margin-left: 10px;

  content: url('data:image/svg+xml; utf8, <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5797 0L11.6441 0.935593L16.0576 5.34915H0V6.65085H16.0576L11.6441 11.0644L12.5797 12L18.1119 6.4678L18.5593 6L18.1119 5.5322L12.5797 0Z" fill="%23002542"/></svg>');
}

img {
  width: 100%;
}
/* Dots - for slick slider
*/
.slick-dots li {
  width: 14px;
  height: 14px;
}
.slick-dots li button {
  width: 14px;
  height: 14px;
}
.slick-dots li button::before {
  width: 14px;
  height: 14px;

  content: '';

  opacity: 1;
  border: 2px solid #ffffff;
}
.slick-dots li.slick-active button::before {
  opacity: 1;
  color: transparent;
  border: 2px solid #ffffff;
  background: #ffffff;
}
/* Arrows - mostly used with Slick sliders
*****************************************/
.arrow {
  position: absolute;

  padding: 0.7rem;

  -webkit-transition: 0.2s;
  transition: 0.2s;

  border: solid transparent;
  border-width: 0 0 2rem 2rem;
  -webkit-box-shadow: 2px -2px #ffffff inset;
  box-shadow: 2px -2px #ffffff inset;
}
.arrow:hover {
  cursor: pointer;

  -webkit-box-shadow: 2px -2px #5091cd inset;
  box-shadow: 2px -2px #5091cd inset;
}
.arrow-up {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.arrow-down {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.arrow-right {
  right: -15px;

  -webkit-transform: translateZ(1px) translateY(-50%) rotate(225deg);
  transform: translateZ(1px) translateY(-50%) rotate(225deg);
}
.arrow-left {
  left: -15px;

  -webkit-transform: translateZ(1px) translateY(-50%) rotate(45deg);
  transform: translateZ(1px) translateY(-50%) rotate(45deg);
}
.slick-arrow {
  z-index: 1;
  top: 50%;

  margin: 0 auto;
}

/* UCD Date
*/
.ucd-date .day {
  font-size: 1.6em;
  font-weight: 900;
}
.ucd-feed-training .section-item {
  position: relative;
}
@media all and (min-width: 768px) {
  .ucd-feed-training .button--large {
    position: absolute;
    bottom: 15px;

    width: calc(100% - 30px);
  }
}
/* Navbar Styling
*****************/

/*
Bootstrap reset
**************/
.nav > li > a:hover,
.nav > li > a:focus {
  background: transparent;
}

/*
    NAVIGATION - MENU
*/

/* body */

body {
  padding-top: 54px;
}
body.js-menu-open {
  position: fixed;
}
/*body.s-lg-guide-body
{
    padding-top: 54px;
}*/
@media (min-width: 768px) {
  body {
    padding-top: 144px;
  }
}
@media (min-width: 992px) {
  body {
    padding-top: 122px;
  }
}
@media (min-width: 1440px) {
  body {
    padding-top: 100px;
  }
}
.search-bar {
  position: relative;

  margin: 15px 0;
  @media all and (min-width: 768px) {
    width: 500px;
    margin: 0;
  }
}
.search-bar #general-search {
  width: 100%;
}
.search-bar input {
  font-weight: normal;

  width: 100%;
  padding: 15px;

  border: 1px solid #eeeeee;
  border-radius: 3px;
  background: #fafbfb;
  @media all and (min-width: 768px) {
    font-size: 14px;
  }
}
.search-bar input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000;
}
.search-bar input::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000;
}
.search-bar input:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000;
}
.search-bar input:-moz-placeholder {
  /* Firefox 18- */
  color: #000000;
}
.search-bar button {
  padding: 15px;

  color: #ffffff;
  border: 0;
  background: #0a4773;
}
.navbar-header .search-bar--float-search {
  @media all and (min-width: 768px) {
    margin-top: 30px;
  }
}
.search-bar--float-search button {
  position: absolute;
  right: 0;

  color: #0a4773;
  background: transparent;
}

.navbar-nav {
  position: relative;
  /*top: 25px;*/

  width: 100%;
  margin: 20px 5px 0;
  padding: 0;

  transition: 300ms all 250ms;
  transform: translate3d(100%, 0, 0);

  opacity: 0;

  will-change: transform, opacity;
}
// .ucd-global-nav .navbar-nav {
//     padding: 0 15px;
// }
.navbar-offcanvas .navbar-collapse[aria-expanded='true'] .navbar-nav {
  transform: translate3d(0, 0, 0);

  opacity: 1;
}

/* hamburger */
.navbar-toggle {
  position: relative;

  margin: 0;
  margin-top: 22px;
  padding: 0;
}
.navbar-offcanvas .navbar-toggle .icon-bar {
  margin-right: auto;
  margin-left: auto;

  transition: transform 300ms cubic-bezier(0.55, 0, 0.1, 1),
    opacity 300ms cubic-bezier(0.55, 0, 0.1, 1);

  background-color: #0a4773;

  will-change: transform, opacity;
}

.navbar-offcanvas .navbar-toggle .icon-bar:nth-child(2) {
  transform: rotate(45deg) translate3d(4px, 5px, 0);
}

.navbar-offcanvas .navbar-toggle .icon-bar:nth-child(3) {
  opacity: 0;
}

.navbar-offcanvas .navbar-toggle .icon-bar:nth-child(4) {
  transform: rotate(-45deg) translate3d(3px, -5px, 0);
}

.navbar-offcanvas .navbar-toggle.collapsed .icon-bar {
  transform: rotate(0) translate3d(0, 0, 0);

  opacity: 1;
}

/* end of hamburger */

/*.navbar-collapse::before
{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';

    opacity: .05;
    background: url('/images/menu/watermark.svg') no-repeat;
    background-size: cover;
}*/
.navbar-offcanvas .navbar-collapse {
  position: fixed;
  z-index: 100;
  top: 60px;
  right: 0;
  left: 0;

  display: block;
  overflow-x: hidden;

  width: 100%;
  height: 100%;
  min-height: calc(100vh - 50px) !important;

  transition: transform 200ms cubic-bezier(0.1, 0, 0.1, 1);
  transform: translate3d(0, 0, 0);

  border: none;
  background: #ffffff;

  will-change: transform;
  @media all and (min-width: 992px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media all and (min-width: 1440px) {
    width: 100% !important;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.navbar-offcanvas .navbar-collapse,
.navbar-offcanvas .navbar-collapse[aria-expanded='false'] {
  transform: translate3d(-100%, 0, 0);
}

.navbar-offcanvas .navbar-collapse[aria-expanded='true'] {
  transform: translate3d(0, 0, 0);
}

.navbar--custom {
  max-width: 2000px;
  margin: 0 auto;

  color: #0a4773;
  border: 0;
  // border-top: 3px solid #2993D0;
  background: #ffffff;
  // box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16), 0 2px 2px rgba(0, 0, 0, 0.23);
  @media all and (min-width: 1440px) {
    width: 100%;
    height: 100px;
    .container-fluid {
      display: flex;
      align-items: center;

      height: 100%;
      &::before,
      &::after {
        display: none;
      }
    }
  }
}

.navbar--custom .navbar-nav {
  @media all and (min-width: 768px) {
    max-width: 700px;
    margin: 0;
    margin-top: 10px;
    margin-left: 0;
  }
  @media all and (min-width: 1440px) {
    max-width: none;
  }
  a {
    font-size: 1.4em;
    font-weight: 600;

    padding: 10px 0;

    transition: all 200ms ease;

    color: #0a4773;
  }
}
.navbar--custom .navbar-nav a:hover,
.navbar--custom .navbar-nav a:focus {
  color: #2993d0;
  outline: inherit;
}
.navbar--custom .navbar-nav span a {
  text-decoration: underline;

  color: #2993d0;
}
.navbar-brand {
  z-index: 1;

  height: 60px;
  padding: 6px 0;
  @media all and (min-width: 768px) {
    height: 80px;
    margin-top: 10px;
    padding: 5px 0;
  }
  @media all and (min-width: 1440px) {
    margin-right: 30px !important;
  }
  @media all and (min-width: 1600px) {
    margin-right: 50px !important;
  }
}
nav .container-fluid {
  padding-right: 10px;
  padding-left: 10px;
  > .navbar-header,
  > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}
.navbar-brand img,
.navbar-brand svg {
  width: auto;
  max-width: 301px;
  height: 100%;
}
.ucd-nav-title {
  font-weight: 600;

  margin-left: 5px;

  color: #0a4773;
}
nav .container-fluid {
  @media all and (min-width: 768px) {
    padding: 0 5%;
  }
  @media all and (min-width: 992px) {
    padding: 0 7.5%;
  }
  @media all and (min-width: 1200px) {
    padding: 0 10%;
  }
  @media all and (min-width: 1440px) {
    padding: 0 15%;
  }
}

@media all and (min-width: 768px) {
  #main-nav > .nav > li:first-child a {
    font-size: 1.5em;
  }
  .navbar-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::before,
    &::after {
      display: none;
    }
  }
  .navbar--custom .navbar-nav span a {
    text-decoration: none;
  }
  .navbar--custom .navbar-nav .fa {
    font-size: 2em;
  }
  // .navbar--custom .navbar-nav li {
  //     max-width: 100px;
  // }
  // .navbar--custom .navbar-nav li:not(:first-child)::before {
  //     position: absolute;
  //     top: 50%;
  //     left: -3px;

  //     width: 1px;
  //     height: 25px;

  //     content: "";
  //     transform: translateY(-50%);

  //     background: #0A4773;
  // }
  // .navbar--custom .navbar-nav li:last-child::after {
  //     position: absolute;
  //     top: 50%;
  //     right: -3px;

  //     width: 1px;
  //     height: 25px;

  //     content: "";
  //     transform: translateY(-50%);

  //     background: #0A4773;
  // }
  .navbar--custom .navbar-nav a {
    font-size: 14px;
    font-weight: 300;

    position: relative;
  }
  .s-lg-guide-body .navbar--custom .navbar-nav a {
    font-size: 1.1em;
  }
  .navbar-nav {
    position: static;
    top: auto;
    /*width: auto;*/

    transform: translateY(0);

    opacity: 1;
  }
  // .navbar-nav li {
  //     padding: 0 10px;
  // }
  .navbar-nav a::after,
  .navbar-nav span a::after {
    position: absolute;
    right: 0;
    bottom: 3px;
    left: 0;

    width: 0;
    height: 0;
    margin: 0 auto;

    content: '';
    transition: ease 300ms;

    background: #2993d0;
  }
  .navbar-nav a:hover::after,
  .navbar-nav span a::after {
    width: 100%;
    height: 3px;
  }
  .navbar-nav li:first-child a:hover::after,
  .navbar-nav li:first-child span a::after {
    height: 0;
  }
  .navbar-offcanvas .navbar-collapse {
    position: static;

    width: auto;
    max-width: none;
    height: auto !important;
    min-height: auto !important;

    transform: translate(0, 0) !important;

    background: transparent;
  }
  .navbar--custom .navbar-nav > li.active > a {
    background: rgba(255, 255, 255, 0.33);
  }
  .navbar-header {
    float: none;
  }
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .navbar-header {
    display: flex;
    align-items: center;
  }
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: 0;
  }
  .navbar-header,
  .navbar-brand {
    position: relative;

    width: 100%;
  }
  .navbar-brand img,
  .navbar-brand svg {
    margin: 0 auto;
  }
  .navbar-nav {
    float: none !important;

    padding-right: 0;
    padding-left: 0;
  }
  .navbar-nav li {
    display: inline-block;
    float: none;
  }
  .navbar-offcanvas .navbar-collapse {
    position: relative;
    top: 0;
    /*margin-top: 25px;*/
  }
  // nav .container-fluid {
  //     padding: 0 5%;
  // }
  // .search-bar {
  //     margin-right: 0;
  //     margin-left: 0;
  // }
}

// Hide the Home button from the main nav
#main-nav {
  .navbar-nav {
    li:first-child {
      display: none;
    }
  }
}

@media all and (min-width: 992px) {
  // .inner nav .container-fluid {
  //     padding: 0 50px;
  // }
  .main-nav-flex {
    display: flex;
    justify-content: flex-end;

    flex-flow: column wrap;
  }
  // #main-nav .main-nav-flex .navbar-nav {
  //     max-width: 625px;
  // }
  // .navbar--custom .navbar-nav li {
  //     max-width: none;
  // }
  .navbar-header {
    float: left;
  }
  // .navbar-offcanvas .navbar-collapse {
  //     margin-top: 15px;
  //     margin-bottom: 15px;
  // }
  .navbar {
    font-size: 12px;
    font-weight: 600;
  }
  .navbar-brand {
    position: relative;
    /*z-index: 1;*/
    /*top: 10px;
        height: 85px;*/
    top: 0;

    height: 100px;
    margin-top: 12px;
    margin-right: 30px;
    margin-left: 0 !important;
    padding: 0;
  }
  #main-nav .navbar-nav {
    position: relative;

    padding: 0;

    transition: 0s;
  }
  #main-nav a {
    font-size: 14px;
    line-height: 1.45;
  }
  // #main-nav .search-bar {
  // width: 50%;
  // }
  // nav .container-fluid {
  //     padding: 0 7.5%;
  // }
}

@media all and (min-width: 1440px) {
  // .inner nav .container-fluid {
  //     padding: 0 5%;
  // }
  // nav .container-fluid {
  //     padding: 0 10%;
  // }
  #main-nav a {
    font-size: 14px;

    margin-right: 7px;
    margin-left: 7px;
    @media all and (min-width: 1500px) {
      font-size: 15px;
    }
    @media all and (min-width: 1530px) {
      font-size: 16px;
    }
  }
  .navbar-nav > li > a {
    padding-right: 20px;
    padding-left: 20px;
  }
  .navbar-brand {
    height: 70px;
    margin-top: 0;
  }
  .main-nav-flex {
    flex-flow: row nowrap;
  }
  // #main-nav .navbar-nav {
  // max-width: 75%;
  // padding-right: 20px;
  // padding-left: 5%;
  // }
  #main-nav .search-bar {
    display: flex;
    align-items: center;

    max-width: 140px;
    margin-left: 20px;

    order: 2;
    // @media all and (min-width: 1600px) {
    // max-width:150px;
    // }
    @media all and (min-width: 1800px) {
      max-width: 180px;
    }
  }
  #main-nav .navbar-nav {
    margin-top: 0;
  }
  #ucdSiteLinks {
    width: 100%;
  }
}

@media all and (min-width: 1440px) {
  // #main-nav .search-bar {
  //     max-width: 160px;
  //     margin-left: 30px;
  // }
  // .inner nav .container-fluid {
  //     padding: 0 10%;
  // }
  // nav .container-fluid {
  //     padding: 0 15%;
  // }
  // .navbar-nav > li > a {
  //     padding-right: 25px;
  //     padding-left: 25px;
  // }
  // #main-nav .navbar-nav {
  //     padding-right: 40px;
  //     padding-left: 5%;
  // }
  // #main-nav .main-nav-flex .navbar-nav {
  //     max-width: 850px;
  // }
}

@media all and (min-width: 1520px) {
  // #main-nav .navbar-nav {
  //     padding-left: 10%;
  // }
  // #main-nav a {
  //     font-size: 1.3em;
  // }
}
@media all and (min-width: 1600px) {
  #main-nav .search-bar {
    margin-left: 50px;
  }
  // #main-nav .main-nav-flex .navbar-nav {
  //     padding: 0;
  // }
  // .inner nav .container-fluid {
  //     padding: 0 15%;
  // }
}
// @media all and (min-width: 1650px) {
//     #main-nav .main-nav-flex .navbar-nav {
//         padding: 0 40px;
//     }
// }
// @media all and (min-width: 1700px) {
//     #main-nav .main-nav-flex .navbar-nav {
//         padding: 0 5% 0 10%;
//     }
// }
// @media all and (min-width: 1800px) {
//     #main-nav .main-nav-flex .navbar-nav {
//         padding: 0 10% 0 10%;
//     }
// }
/* UCD general navigation
************************/
.ucd-global-nav {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;

  position: relative;

  margin: 15px auto;
}
.ucd-global-nav::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  width: 25%;
  height: 5px;
  margin: 0 auto;

  content: '';

  background: #2993d0;
}
#ucdSiteLinks {
  margin-top: 0;
  padding: 0;
  @media all and (min-width: 768px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
#ucdSiteLinks a {
  font-size: 14px;
  font-weight: 300;

  margin: 0;
  padding: 5px;
}
#ucdSiteLinks li:last-child {
  margin-top: 10px;
  padding: 10px 0;

  border-top: 1px solid #2993d0;
  border-bottom: 1px solid #2993d0;
  @media all and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.toggle-ucd-global {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;

  display: none;
}
@media all and (min-width: 768px) {
  .toggle-ucd-global {
    font-size: 11px;
    line-height: 1.5;

    position: absolute;
    right: 20px;
    bottom: -26px;

    display: block;

    padding: 5px 10px;

    cursor: pointer;

    color: #ffffff;
    background: #008acc;
  }
  .ucd-global-nav.js-triggered {
    top: -115px;

    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .js-triggered .toggle-ucd-global {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .js-triggered .fa-caret-down {
    transform: rotate(180deg);
  }
  .ucd-nav-title {
    display: none;
  }
  .ucd-global-nav::before {
    height: 0;
  }
  .ucd-global-nav {
    position: absolute;
    top: -210px;
    left: calc(-5% - 5px);
    /*display: none;*/

    width: calc(110% + 10px);
    margin-bottom: 0;
    margin-left: 0;

    transition: all 300ms;

    background: #008acc;
    @media all and (min-width: 992px) {
      // top: -105px;
      top: -100px;
      left: 0;

      width: 100%;
      width: calc(110% + 10px);
      margin-left: calc(-5% - 5px);
      // left:calc(-7.5% - 15px);
    }
    @media all and (min-width: 1200px) {
      // top:-105px;
      width: calc(120% + 50px);
      margin-left: calc(-10% - 25px);
    }
    @media all and (min-width: 1440px) {
      // top: -91px;
      top: -88px;
      // width: calc(130% + 30px);

      width: calc(140% + 70px);
      margin-left: calc(-20% - 110px);
    }
  }
  #ucdSiteLinks {
    display: inline-block;

    max-width: none;
    padding-right: 10px;
    padding-left: 10px;
  }
  #ucdSiteLinks li {
    display: inline-block;

    width: auto;
    max-width: none;
  }
  #ucdSiteLinks li::before {
    width: 0;
  }
  #ucdSiteLinks a {
    font-size: 12px;

    color: #ffffff;
  }
  .ucd-global-nav .search-bar {
    display: inline-block;

    width: 100%;
    margin-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    input {
      border-radius: 0;
    }
  }
  #ucdSiteLinks li:last-child {
    margin-top: 0;

    border: 0;
  }
  .navbar--custom .navbar-nav li:last-child::after {
    width: 0;
  }
  .search-bar input,
  .search-bar--float-search button {
    padding: 10px;
  }
}

@media all and (min-width: 992px) {
  // .ucd-global-nav .search-bar {
  //     width: 20%;
  //     max-width: 250px;
  //     margin: 0;
  //     margin-top: 5px;
  //     margin-bottom: 10px;
  //     margin-left: 10px;

  //     text-align: right;
  // }
  #ucdSiteLinks {
    // display: flex;
    // justify-content: flex-start;
    // width: 100%;
    // padding: 0;
    padding: 0 10px !important;
  }
  .ucd-global-nav.js-triggered {
    top: -15px;
    @media all and (min-width: 1440px) {
      top: -30px;
    }
  }
  .ucd-global-nav {
    // top: -135px;
    // width: calc(110% + 10px);
    // margin-left: calc(-5% - 5px);
    // padding-top: 0;
    margin-top: 0;
    @media all and (min-width: 1440px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding-top: 10px;
      padding-bottom: 10px;
      .search-bar {
        max-width: 250px !important;
        margin: 0;
        margin-left: 20px;
      }
      .navbar-nav {
        margin-bottom: 0 !important;
        li,
        a {
          font-size: 14px !important;
        }
        a {
          padding-right: 15px !important;
        }
      }
      // margin-top: 0 !important;
      // .search-bar {
      // margin-left: 0 !important;
      // }
    }
  }
}
@media all and (min-width: 1200px) {
  // .toggle-ucd-global {
  //     right: 30px;
  // }
  // .ucd-global-nav {
  // top: -90px;

  // width: calc(120% + 50px);
  // margin-left: calc(-10% - 25px);
  // }
}
@media all and (min-width: 1440px) {
  // .toggle-ucd-global {
  // right: 15%;
  // }
  // .ucd-global-nav {
  //     width: calc(140% + 70px);
  //     margin-left: calc(-20% - 50px);
  // }
}

/*amends to the nav*/

// @media all and (min-width: 768px) and (max-width: 991px) {
//     .navbar-header .navbar-brand {
//         display: inline-block;
//         float: left;

//         width: 30%;
//         height: 80px;
//     }
//     .navbar-header .search-bar {
//         display: inline-block;
//         float: left;

//         width: 50%;
//     }
// }

/* Alert message
***************/

.message {
  position: relative;
  z-index: 500;
  top: 0;

  display: flex;
  align-items: center;

  padding: 15px;

  text-align: center;

  background: #ffffff;

  /*display: none;*/
}
.message__wrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 750px;
  max-width: 100%;
  margin: 0 auto;

  word-break: break-word;
}
.message p {
  margin-bottom: 0;
}
.message img {
  display: inline-block;

  width: 25px;
  margin-right: 25px;

  text-align: center;
}
.message--alert {
  background: #cd1109;
}
.message--alert p {
  display: inline-block;

  width: 100%;

  color: #ffffff;
}

/*@media all and (min-width: 768px)
{
    .message
    {
        margin-top: 30px;
    }
}*/
@media all and (min-width: 992px) {
  .message {
    margin-top: 0;
  }
}
/* Banner
********/
.ucd-banner {
  position: relative;

  background: #eeeeee;
}

.ucd-banner .banner-edge {
  position: absolute;
  bottom: -5px;

  width: 100%;
  /*background: #eee;*/
}
.ucd-banner-background {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 300px;
  padding: 25px;
  padding-bottom: 100px;

  /*clip-path: polygon(0 0, 0 80%, 0 90%, 50% 100%, 100% 90%, 100% 80%, 100% 0);*/
}
.ucd-banner-message {
  padding: 15px;

  text-align: center;

  background: rgba(41, 147, 208, 0.75);
}
.ucd-banner-message h1 {
  font-size: 2em;
}
.ucd-banner-message p {
  margin-bottom: 0;
  padding: 15px 0;

  color: #ffffff;
}
@media all and (min-width: 768px) {
  /*.ucd-banner-background
    {
        clip-path: polygon(0 0, 0 90%, 40% 90%, 50% 100%, 60% 90%, 100% 90%, 100% 0);
    }*/
  .ucd-banner-message {
    max-width: 900px;
    /*margin: 0 auto;*/
    padding: 30px 100px;
  }
  .ucd-banner-message h1 {
    font-size: 2.2em;
  }
}
@media all and (min-width: 1200px) {
  .ucd-banner-message {
    padding: 40px 150px;
  }
}
/* UCD cards links
*****************/
.ucd-cards-links {
  background: #eeeeee;
}
.ucd-cards-links__container {
  display: flex;
  justify-content: space-between;

  max-width: 1360px;
  margin: 0 auto;
  padding: 20px 15px;

  flex-flow: row wrap;
}
.ucd-card-link {
  display: block;

  width: 100%;
  margin-bottom: 15px;

  text-decoration: none;

  background: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(1, 2, 2, 0.15);
}
.ucd-card-link-title,
.ucd-card-link-image,
.ucd-card-link-text {
  position: relative;

  display: block;
}
.ucd-card-link-title {
  padding: 10px;

  background: #2993d0;
}
.ucd-card-link-title::after {
  font-family: 'FontAwesome';

  position: absolute;
  top: 50%;
  right: 15px;

  content: '\f054';
  transform: translateY(-50%);
}
.ucd-card-link:nth-child(2n) .ucd-card-link-title {
  background: #00984a;
}
.ucd-card-link:nth-child(3n) .ucd-card-link-title {
  background: #f0c650;
}
.ucd-card-link:nth-child(4n) .ucd-card-link-title {
  background: #0a4773;
}

.ucd-card-link-title h1 {
  font-size: 1.3em;

  display: flex;
  align-items: center;

  width: 75%;
}
.ucd-card-link-image {
  display: none;

  height: 250px;

  background-position: center;
  background-size: cover;
}
.ucd-card-link-text {
  z-index: 1;

  padding: 10px;

  color: #555555;
}
.ucd-card-link-text p {
  position: relative;
  z-index: 2;

  margin-top: 15px;
  margin-bottom: 0;
}
.ucd-card-link:hover,
.ucd-card-link:focus {
  text-decoration: none;
}
.shape-down,
.shape-up {
  display: none;
}
@media all and (min-width: 768px) {
  .ucd-card-link {
    width: calc(50% - 10px);
    margin-right: 5px;

    transition: all 250ms;
  }

  .ucd-card-link:nth-child(2n) {
    margin-right: 0;
    margin-left: 5px;
  }
  .ucd-card-link-title::after {
    display: none;
  }
  .ucd-card-link-title {
    min-height: 108px;
    padding: 5px 25px;
  }
  .ucd-card-link-image {
    display: block;

    transition: all 250ms;
  }
  .ucd-card-link-text {
    padding: 15px;
  }
}
@media all and (min-width: 992px) {
  .ucd-card-link:hover {
    box-shadow: 0 5px 10px 0 rgba(1, 2, 2, 0.15);
  }
  .ucd-card-link,
  .ucd-card-link:nth-child(2n) {
    width: calc(25% - 16px);
    margin-right: 8px;
    margin-left: 0;
  }
  .ucd-card-link:last-child {
    margin-right: 0;
  }
  .ucd-card-link-title h1 {
    position: relative;
    z-index: 2;
  }
  .ucd-card-link-title {
    z-index: 1;
  }
  .ucd-card-link-title::before {
    /*position: absolute;
        z-index: 1;
        top: 28px;
        left: 0;

        width: 100%;
        height: 100px;

        content: '';
        transition: all 250ms;
        transform: translate3d(0,-20px,0);

        background: #2993d0;

        will-change: transform;
        clip-path: polygon(50% 100%, 0 80%, 100% 80%);*/
  }
  .ucd-card-link:hover .ucd-card-link-title::before {
    transform: translate3d(0, 0, 0);
  }
  .ucd-card-link:nth-child(n) .shape-up .svg-background {
    fill: #2993d0;
  }
  .ucd-card-link:nth-child(2n) .ucd-card-link-title::before,
  .ucd-card-link:nth-child(2n) .shape-up .svg-background {
    fill: #00984a;
  }
  .ucd-card-link:nth-child(3n) .ucd-card-link-title::before,
  .ucd-card-link:nth-child(3n) .shape-up .svg-background {
    fill: #f0c650;
  }
  .ucd-card-link:nth-child(4n) .ucd-card-link-title::before,
  .ucd-card-link:nth-child(4n) .shape-up .svg-background {
    fill: #0a4773;
  }
  .ucd-card-link-text::before {
    /*position: absolute;
        z-index: 1;
        left: 0;

        width: 100%;
        height: 100%;


        content: '';
        transition: all 250ms;
        transform: translate3d(0,20px,0);
        will-change: transform;*/

    /*clip-path: url(#clip-shape);*/
    /*clip-path: polygon(50% 80%, 0 100%, 100% 100%);*/
  }
  .ucd-card-link:hover .ucd-card-link-text::before {
    /*transform: translate3d(0,0,0);*/
    /*height: 100px;*/
  }
  .ucd-card-link {
    position: relative;
  }
  .shape-down,
  .shape-up {
    display: block;
  }

  #image-wrapper {
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;

    margin: 0 auto;

    transition: all 250ms;

    background: transparent;
  }
  #image-wrapper.shape-down {
    bottom: 15px;
  }
  #image-wrapper.shape-up {
    top: 0;
  }
  .ucd-card-link:hover #image-wrapper.shape-down {
    bottom: 92.85%;
  }
  .ucd-card-link:hover #image-wrapper.shape-up {
    top: 108px;
  }
  .shape-down .svg-background,
  .shape-down .svg-image-down {
    clip-path: url(#clip-shape-down);
  }
  .shape-up .svg-background,
  .shape-up .svg-image-down {
    clip-path: url(#clip-shape-up);
  }
  .svg-background {
    fill: #ffffff;
  }
  .clip-svg {
    width: 100%;
    height: 50px;
  }
  .shape-up .clip-svg {
    height: 25px;
  }
}

/* Box content
*************/
.ucd-box-content-section {
  background: #eeeeee;
}
.ucd-box-content__container {
  max-width: 1360px;
  margin: 0 auto;
  padding: 10px 15px 5px 15px;
}
.ucd-layout-content-inner--large .ucd-box-content__container {
  padding: 0;
}
.ucd-box-content {
  display: flex;

  margin-bottom: 15px;

  background: #ffffff;
  box-shadow: 0 1px 8px 0 rgba(1, 2, 2, 0.15);

  flex-flow: row wrap;
}
.ucd-layout-content-inner--large .ucd-box-content {
  box-shadow: none;
}
.ucd-box-content-image {
  width: 100%;
  height: 150px;

  background-position: top;
  background-size: cover;
}
.ucd-box-content-text {
  width: 100%;
  padding: 20px;
}
.ucd-layout-content-inner--large .ucd-box-content-text {
  padding: 0;
}
.ucd-layout-content-inner--large .has-title .ucd-box-content-text {
  padding: 15px;

  border: 1px solid #eeeeee;
}
.ucd-box-content-title {
  border-bottom: 1px solid #eeeeee;
}
.ucd-box-content-title h1 {
  font-size: 1.5em;

  padding: 20px 0 30px;

  color: #0a4873;
}
.ucd-layout-box-content ul,
.ucd-box-content ul {
  padding-top: 15px;
  /*padding-bottom: 15px;*/
  padding-left: 0;

  list-style-type: none;
}
.ucd-layout-box-content p + ul,
.ucd-layout-box-content ul + ul,
.ucd-layout-box-content ul + p {
  margin-top: 0;
  padding-top: 0;
}
.ucd-layout-box-content ul li,
.ucd-box-content ul li {
  position: relative;

  margin-bottom: 20px;
  margin-left: 25px;
}
.ucd-layout-box-content ol li {
  margin-bottom: 20px;
}
.ucd-layout-box-content ul li::before,
.ucd-box-content ul li::before {
  font-family: 'FontAwesome';
  font-size: 0.7em;

  position: absolute;
  top: 50%;
  left: -20px;

  content: '\f054';
  transform: translateY(-50%);

  color: #008acc;
}
.ucd-box-content-wysiwyg a {
  text-decoration: none;

  color: #008acc;
  box-decoration-break: clone;
}
/*.ucd-layout-box-content li a,
.ucd-box-content li a
{
    padding-left: 25px;
}*/
.ucd-box-content-wysiwyg a:hover {
  color: #0a4773;
}

.ucd-layout-content-inner .ucd-box-content-wysiwyg h1,
.ucd-layout-content-inner h1 {
  font-size: 2em;
}
.ucd-layout-content-inner .ucd-box-content-wysiwyg h2,
.ucd-layout-content-inner h2 {
  font-size: 1.8em;

  margin: 5px 0;
}
.ucd-layout-content-inner .ucd-box-content-wysiwyg h3,
.ucd-layout-content-inner h3 {
  font-size: 1.6em;
}
.ucd-layout-content-inner .ucd-box-content-wysiwyg .js-responsive-table h3 {
  font-size: 14px;
}
.ucd-layout-content-inner .ucd-box-content-wysiwyg h4,
.ucd-layout-content-inner h4 {
  font-size: 1.4em;

  color: #0a4773;
}
.ucd-layout-content-inner .ucd-box-content-wysiwyg h5,
.ucd-layout-content-inner h5 {
  font-size: 14px;

  color: #008acc;
}
.ucd-layout-content-inner .ucd-box-content-wysiwyg h6,
.ucd-layout-content-inner h6 {
  font-size: 14px;

  color: #000000;
}
.ucd-layout-content-inner .ucd-box-content-wysiwyg ol {
  padding-left: 25px;
}

.ucd-box-content-wysiwyg p {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  line-height: 1.7;

  margin: 20px auto;

  color: #555555;
}
.ucd-box-content-wysiwyg blockquote {
  font-size: 14px;

  border: 1px solid #7cd0ff;
  background: #e7f1f5;
}
.ucd-box-content-wysiwyg blockquote p {
  margin: 0;
  padding: 5px;
}
.ucd-box-content-wysiwyg img {
  max-width: 100% !important;
  height: auto !important;
}

@media all and (min-width: 520px) {
  .ucd-box-content-image {
    height: 350px;
  }
}
@media all and (min-width: 768px) {
  .ucd-box-content-text {
    padding: 25px;
  }
  .ucd-box-content-wysiwyg.wysiwyg--text-2 p {
    columns: 2;
  }
  .ucd-box-content-wysiwyg.wysiwyg--text-3 p {
    columns: 2;
  }
  .ucd-box-content-wysiwyg.wysiwyg--list-2 ul {
    columns: 2;
  }
  .ucd-box-content-wysiwyg.wysiwyg--list-3 ul {
    columns: 3;
  }
  .ucd-box-content-wysiwyg.wysiwyg--list-4 ul {
    columns: 3;
  }

  .ucd-layout-box-content ul {
    margin-top: 10px;
    padding-left: 0;
  }
}
@media all and (min-width: 992px) {
  .ucd-box-content {
    flex-flow: row nowrap;
  }
  .ucd-layout-content-inner .ucd-box-content {
    margin-bottom: 50px;
  }
  .ucd-box-content-image {
    width: auto;
    min-width: 30%;
    height: auto;

    background-position: center;
  }
  .ucd-box-content-text {
    padding: 30px;
  }
}
@media all and (min-width: 1200px) {
  .ucd-box-content-text {
    padding: 40px;
  }
}
@media all and (min-width: 1200px) {
  .ucd-box-content-text {
    padding: 50px;
  }
}
/* download-link */
.ucd-layout-content-inner .download-link:hover {
  text-decoration: none;

  background: #008acc;
}
.ucd-layout-content-inner .download-link {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
  padding: 15px;

  transition: 350ms;

  color: #ffffff;
  /*border-radius: 50px;*/
  background: #0a4773;

  flex-wrap: wrap;
}
.ucd-layout-content-inner .download-link .title {
  margin: 10px;

  text-align: center;

  flex: 0 0 75%;
}
.ucd-layout-content-inner .download-link .type,
.ucd-layout-content-inner .download-link .size {
  display: block;

  margin: 0 10px;
  padding: 5px 10px;

  border-radius: 8px;
  background: #f8f8f8;

  flex: 0 0 40%;
}
.ucd-layout-content-inner .download-link .size {
  color: #0a4773;
}
.ucd-layout-content-inner .download-link .type {
  background: #008acc;
}
.ucd-layout-content-inner .download-link i {
  font-size: 14px;

  margin-right: 15px;

  text-align: center;

  flex: 0 0 10%;
}
@media all and (min-width: 768px) {
  .ucd-layout-content-inner .download-link i {
    font-size: 14px;
  }
  .ucd-layout-content-inner .download-link {
    margin: 25px 0;
    padding: 5px 15px;
  }
  .ucd-layout-content-inner .download-link i,
  .ucd-layout-content-inner .download-link .title,
  .ucd-layout-content-inner .download-link .type,
  .ucd-layout-content-inner .download-link .size {
    flex: auto;
  }
  .ucd-layout-content-inner .download-link .title {
    font-size: 14px;
  }
}
/*staff-profile-group*/

.staff-profile-group {
  display: flex;
  overflow-x: auto;

  width: 100%;
  margin: 15px auto;
  padding: 0 5px;

  flex-flow: row wrap;
}
.pagination {
  margin: 5px 15px 5px 15px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span,
.pagination > li > a,
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-right: 10px;

  border-radius: 25px;
}
.staff-profile-group::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.staff-profile-group::-webkit-scrollbar {
  width: 12px;

  background-color: #f5f5f5;
}

.staff-profile-group::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #008acc;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
/*sorry but i had no choice*/
.staff-profile-group .staff-profile {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  /*border: 1px solid #f8f8f8;*/

  background: #f8f8f8;
  box-shadow: 0 1px 8px 0 rgba(1, 2, 2, 0.15);
}
.staff-profile-group .staff-profile .box {
  display: flex;
}
.staff-profile-group .staff-profile .box > a {
  width: 60%;
  max-width: 120px;
  padding: 0;
}
.staff-profile-group .staff-profile .intro {
  width: 100%;
  padding-left: 15px;
}
.staff-profile-group .staff-profile .intro h3 {
  padding: 0;
}
.staff-profile-group .staff-profile .intro h3 a {
  font-size: 0.8em;

  padding: 0 0 10px 0;
}
.staff-profile-group .staff-profile .intro a {
  font-size: 0.9em;
}
.staff-profile-group .staff-profile .intro p {
  font-size: 0.9em;

  position: relative;

  padding-bottom: 15px;
}
.staff-profile-group .staff-profile .intro p::after {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 1px;

  content: '';

  background: #f0f0f0;
}
.staff-profile-group .staff-profile a.email:hover,
.staff-profile-group .staff-profile a.phone:hover {
  opacity: 0.5;
}
.staff-profile-group .staff-profile a.email:before,
.staff-profile-group .staff-profile a.phone:before {
  font-family: 'FontAwesome';

  position: relative;

  margin-right: 15px;

  content: '\f04d';
}
.staff-profile-group .staff-profile a.email:before {
  content: '\f003';
}
.staff-profile-group .staff-profile a.phone:before {
  content: '\f095';
}

.staff-profile-group .staff-profile a {
  display: block;

  text-decoration: none;
}
.staff-profile-group .pagination {
  display: block;
}

@media all and (min-width: 768px) {
  .staff-profile-group {
    flex-wrap: wrap;
  }
  .staff-profile-group .staff-profile {
    width: calc(50% - 10px);
    margin-right: 20px;
  }
  .staff-profile-group .staff-profile:nth-child(2n) {
    margin-right: 0;
  }
}
@media all and (min-width: 992px) {
  .staff-profile-group .staff-profile {
    transition: 250ms;
  }
  .staff-profile-group .staff-profile:hover {
    box-shadow: 0 1px 8px 0 rgba(1, 2, 2, 0.25);
  }
}
@media all and (min-width: 1200px) {
  .staff-profile-group {
    margin: 15px auto;
  }
  /*.staff-profile-group .staff-profile:nth-child(2n),
    .staff-profile-group .staff-profile
    {
        width: calc(33.3333% - 15px);
        margin-right: 20px;

        border-color: #f5f5f5;
        background: #f7f7f7;;
    }
    .staff-profile-group .staff-profile:nth-child(3n)
    {
        margin-right: 0;
    }*/
}
/* Accordions
************/

/* Boostrap overwrite */

.panel-heading,
.panel-group .panel,
.panel-default,
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.panel-default > .panel-heading {
  border: 0;
  border-bottom: 1px solid #eeeeee;
  background: transparent;
}
.panel-group {
  padding-bottom: 20px;

  /*border-bottom: 1px solid #eeecec;*/
}

.panel-heading {
  background: transparent;
}
.panel-title {
  position: relative;
}

.panel-title > a {
  font-size: 14px;
  font-weight: 300;

  z-index: 1;

  display: inline-block;

  width: 100%;
  max-width: 225px;
  height: 100%;
  padding: 10px 0;

  text-decoration: none;
  text-transform: none;

  color: #0a4873;
}
.panel-title a::after,
.ucd-accordion a.panel-title::after {
  font-size: 42px;
}
.panel-title a::after,
.ucd-accordion a.panel-title::after {
  font-family: 'Open Sans';
  font-size: 36px;

  position: absolute;
  z-index: 0;
  top: 50%;
  right: 15px;

  content: '+';
  transition: 0.2s all;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  color: #0a4873;
}
.ucd-layout--left .ucd-accordion a.panel-title::after {
  color: #ffffff;
}
.panel-title a[aria-expanded='true']::after,
.ucd-accordion a.panel-title[aria-expanded='true']::after {
  transform: translateY(-50%) rotate(-135deg);
}
.panel-heading {
  padding: 0;
}
.panel-group > h2 {
  font-size: 16px;
  font-weight: 300;

  text-transform: uppercase;
}
.panel-group h2 {
  padding: 0;
}
.ucd-accordion .panel-title {
  font-family: 'Roboto Slab', serif;
  font-size: 20px;

  display: block;
  /*max-width: 225px;*/

  margin: 20px 0;

  text-decoration: none;

  color: #0a4873;
}
.ucd-layout--left .ucd-accordion .panel-title {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 16px;

  margin: 0;
  padding: 5px 0;

  color: #ffffff;
}
.ucd-layout-content-inner .panel {
  background: #f8f8f8;
}
.ucd-layout-content-inner .panel-heading {
  padding: 5px 15px;

  background: #008acc;
}
.ucd-layout-content-inner .panel-heading a {
  display: block;

  width: 90%;

  color: #ffffff;
}
.ucd-layout-content-inner .panel-heading a.panel-title {
  text-decoration: none;
}
.ucd-layout-content-inner .panel-heading a::after {
  font-family: 'FontAwesome';
  font-size: 1.5em;

  position: absolute;
  top: 50%;
  right: -7.5%;

  content: '+';
  transition: 300ms;
  transform: translateY(-50%);

  will-change: transform;
}
.ucd-layout-content-inner .panel-heading a:not(.collapsed)::after {
  transform: translateY(-50%) rotate(45deg);
}
.ucd-layout-content-inner .panel-default {
  border-radius: 35px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
.ucd-layout-content-inner .panel-default > .panel-heading {
  border-bottom: 0;
  border-radius: 50px;
}
/* Section
*********/
.regular-section {
  position: relative;
  z-index: 1;

  padding: 40px 10px;

  background: #eeeeee;
  background-position: center;
  background-size: cover;
}
.wide-section {
  // padding-top:40px;
  // padding-bottom:40px;
}
.regular-section--navy {
  background-color: #002542;
}
.regular-section--tiny {
  padding-top: 16px;
  padding-bottom: 16px;
}
.regular-section--blue {
  background-color: #008acc;
}
.regular-section--center {
  text-align: center;
}
.regular-section--darkblue {
  // background: #008acc;
  background-color: #008acc;
}
.regular-section--overlay::before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  content: '';

  background: rgba(10, 71, 115, 0.9);
}
.regular-section--overlay--light::before {
  background: rgba(41, 147, 208, 0.9);
}
.ucd-quicktasks.regular-section::before {
  background: rgba(41, 147, 208, 0.9);
}
@media all and (min-width: 768px) {
  .regular-section {
    padding: 40px 5%;
  }
}
@media all and (min-width: 992px) {
  .regular-section {
    padding: 40px 7.5%;
  }
}
@media all and (min-width: 1200px) {
  .regular-section {
    padding: 40px 10%;
  }
}
@media all and (min-width: 1440px) {
  .regular-section {
    padding: 40px 12.5%;
  }
}
.section-item {
  width: 100%;
  // margin-bottom: 15px;
  padding: 0;

  background: #ffffff;
  & + & {
    @media all and (max-width: 767px) {
      margin-top: 20px;
    }
    @media all and (min-width: 768px) {
      margin-left: 15px;
    }
    @media all and (min-width: 992px) {
      width: calc(60% - 30px);
      margin-left: 15px;
    }
    @media all and (min-width: 1440px) {
      width: calc(65% - 30px);
    }
  }
  @media all and (min-width: 768px) {
    width: 50%;
  }
  @media all and (min-width: 992px) {
    width: 40%;
  }
  @media all and (min-width: 1440px) {
    width: 35%;
  }
}
.section-item--transparent {
  // background: rgba(255, 255, 255, 0.9);
  background: transparent;
}
.section-item h5 {
  color: #0a4773;
}
.section-item table {
  width: 100%;
  // margin-bottom: 15px;

  background: #ffffff;
  span {
    font-weight: bold;
  }
  .open {
    color: #009852;
  }
  .closed {
    color: #eb5757;
  }
}
.section-item table td {
  font-size: 16px;
  line-height: 1.65;

  padding: 5px 10px;

  border-bottom: 1px solid #eeeeee;
  @media all and (min-width: 768px) {
    padding: 8px 15px 7px;
  }
  @media all and (min-width: 1440px) {
    padding: 8px 20px 7px;
  }
  @media all and (min-width: 1600px) {
    padding: 8px 30px 7px;
  }
}
.section-item table tr:last-child td {
  border-bottom: 0;
}
.section-item table td a {
  font-weight: bold;

  text-decoration: underline;

  color: #008acc;
}
.section-item table td:first-child {
  width: 60%;

  color: #004377;
  @media all and (min-width: 1440px) {
    width: 50%;
  }
}
.section-item table td:last-child {
  width: 40%;

  text-align: right;
  @media all and (min-width: 1440px) {
    width: 50%;
  }
}

.section-item table.inverted-position td:first-child {
  width: 15%;
  padding: 5px;

  text-align: center;

  color: #555555;
}
.section-item table.inverted-position td:last-child {
  width: 70%;
  padding: 10px 20px;

  text-align: left;

  color: #2993d0;
}
.section-item .button {
  margin-top: 12px;

  color: white;
  background-color: #004377;
}

.section-item .button--opening-hours-table-row {
  background-color: white;
  color: #004377;
}
/* making twitter iframe more usable on mobile*/
.section-item iframe {
  height: 350px !important;
}
@media screen and (max-width: 460px) {
  .section-item iframe {
    width: 100vw !important;
  }
}
.section-item.section-item--iframe {
  overflow-x: auto;
}
.section-item.section-item--iframe iframe {
  width: 100%;
}
@media all and (min-width: 768px) {
  .section-item__container {
    display: flex;
    justify-content: space-between;

    width: 100%;
  }
  .section-item.section-item--iframe iframe {
    height: calc(100% - 40px) !important;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .section-item.section-item--iframe iframe {
      height: 600px !important;
    }
  }
  .section-item__stacked {
    width: calc(50% - 10px);
  }
  .section-item__stacked .section-item {
    width: 100%;
  }
  /* twitter gets its size back*/
  .section-item iframe {
    height: 600px !important;
  }
}
/* Nav tabs
**********/
.nav {
  border: 0;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover,
.nav-tabs > li > a,
.nav-tabs > li > a:focus,
.nav-tabs > li > a:hover {
  border: 0;
  border-radius: 0;
  /*background: #eee;*/
}

.nav-tabs > li {
  display: block;
  /*float: none;

    margin-bottom: 5px;*/
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: white;
  background: #004377;
}
@media all and (min-width: 992px) {
  .nav-tabs > li {
    display: inline-block;
    float: left;
  }
}
/* Opening hours table
*********************/
.opening-hours-tab a {
  color: #333333;
}
.opening-hours-tab .nav-tabs > li.active > a,
.opening-hours-tab .nav-tabs > li.active > a:hover,
.opening-hours-tab .nav-tabs > li.active > a:focus {
  color: #ffffff;
  background: #008acc;
}
.opening-hours-tab .nav-tabs {
  display: flex;
  overflow-x: scroll;

  margin: 25px 10px 25px 0;
  padding-left: 0;

  list-style-type: none;

  text-align: left;

  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.opening-hours-tab .nav-tabs::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.opening-hours-tab .nav-tabs::-webkit-scrollbar {
  width: 12px;

  background-color: #f5f5f5;
}

.opening-hours-tab .nav-tabs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #008acc;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.opening-hours-tab .nav-tabs li {
  position: relative;

  margin: 5px 5px 15px 5px;

  flex: 0 0 auto;
}
.opening-hours-tab .nav-tabs li:first-child {
  margin-left: 0;
}
.opening-hours-tab .nav-tabs > li > a {
  font-size: 0.9em;

  display: inline-block;

  width: 100%;
  padding: 10px 15px;

  white-space: nowrap;
  text-decoration: none;

  color: #008acc;
  border-radius: 25px;
  /*border: 1px solid #eee;*/
  background: #eeeeee;
}
.opening-hours-tab .tab-content {
  padding: 15px;

  background: #f7f7f7;
}
@media all and (min-width: 768px) {
  .opening-hours-tab .nav-tabs > li > a {
    font-size: 14px;

    padding: 15px 20px;
  }
}
@media all and (min-width: 992px) {
  .opening-hours-tab .nav-tabs {
    overflow: auto;
    align-items: flex-end;

    margin-bottom: 0;
  }
  .opening-hours-tab .nav-tabs li {
    margin: 0 5px;

    flex: 1;
  }
  .opening-hours-tab .nav-tabs li:first-child {
    margin-left: 0;
  }
  .opening-hours-tab .nav-tabs > li > a {
    padding: 15px 10px;

    text-align: center;
    white-space: normal;

    color: #000000;
    border-radius: 0;
    background: transparent;
  }
  .opening-hours-tab .nav-tabs > li.active > a,
  .opening-hours-tab .nav-tabs > li.active > a:hover,
  .opening-hours-tab .nav-tabs > li.active > a:focus {
    color: #008acc;
    background: #f7f7f7;
  }
}

.js-responsive-table {
  font: normal 14px/1.4 sans-serif;

  margin-bottom: 14px;

  color: #666666;
}
.js-responsive-table th,
.js-responsive-table td {
  padding: 20px 10px;

  color: #0f0f0f;
}
.js-responsive-table tr:not(:first-child) th:not(:first-child),
.js-responsive-table tr:not(:first-child) td:not(:first-child) {
  padding: 10px;
}
.js-responsive-table tr h3 {
  font-size: 14px;
}
.js-responsive-table tr {
  border-bottom: 1px solid #dddddd;
}
.js-responsive-table th {
  color: #ffffff;
  background: #008acc;
}
.js-responsive-table th,
.js-responsive-table tr:first-child td {
  font-weight: bold;
}
.js-responsive-table td h3 {
  font-weight: bold;

  display: block;

  margin: 0;
  padding: 0 0 5px 0;
}
.js-responsive-table .note {
  font-size: 0.75em;
  font-weight: 600;

  display: block;
}
.js-responsive-table table,
.js-responsive-table tbody,
.js-responsive-table table tr,
.js-responsive-table table td,
.js-responsive-table table th {
  display: block;

  width: 100%;
}

.js-responsive-table .icon-accordion {
  font-size: 1.6em;
  font-style: normal;

  position: absolute;
  top: 15px;
  right: 15px;

  display: block;

  width: 16px;
  height: 16px;

  text-align: center;
}
.js-responsive-table table tr {
  position: relative;

  overflow: hidden;

  max-height: 60px;

  cursor: pointer;
}
@media all and (min-width: 992px) {
  .js-responsive-table table tr {
    overflow: auto;

    cursor: auto;
  }
  .js-responsive-table table {
    display: table;
  }
  .js-responsive-table tbody {
    display: table-row-group;
  }
  .js-responsive-table table tr {
    display: table-row;

    transition: all 100ms;

    border-bottom: 1px solid #f0f0f0;
  }
  .js-responsive-table table tr:last-child {
    border-bottom: 3px solid #008acc;
  }
  .js-responsive-table table td,
  .js-responsive-table table th {
    font-size: 0.8em;

    display: table-cell;

    width: 11%;
  }
  .js-responsive-table table td {
    transition: all 100ms;

    background: #ffffff;
  }
  .js-responsive-table table td:not(:first-child),
  .js-responsive-table table th:not(:first-child) {
    border-left: 1px solid #f0f0f0;
  }
  .js-responsive-table .icon-accordion {
    display: none;
  }
  .js-responsive-table td h3 {
    display: none;
  }
  .js-responsive-table tr:not(:first-child) th:not(:first-child),
  .js-responsive-table tr:not(:first-child) td:not(:first-child) {
    padding: 5px;
  }
  .js-responsive-table tr:not(:first-child):hover td {
    background: #f0f0f0;
  }
  .js-responsive-table tr:not(:first-child):hover td:hover {
    background: #f5f5f5;
  }
  .js-responsive-table table th:first-child {
    border-top-left-radius: 5px;
  }
  .js-responsive-table table th:last-child {
    border-top-right-radius: 5px;
  }
}
@media all and (min-width: 1200px) {
  .js-responsive-table table th {
    font-size: 0.9em;
  }
  .js-responsive-table table td:first-child,
  .js-responsive-table table th:first-child {
    width: 23%;
  }
  .js-responsive-table tr:not(:first-child) th:not(:first-child),
  .js-responsive-table tr:not(:first-child) td:not(:first-child) {
    padding: 10px;
  }
}
/*

UCD LIBRARY THEME

*/

/* UCD Search
************/
.ucd-search .tab_container {
  position: relative;
  /*background: #fbfafa;*/

  border-radius: 0;
}
.ucd-search .tab-content {
  padding: 15px;

  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background: #004377;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.3);
}
.ucd-search .tab-content > .active {
  display: flex;
}
.ucd-search .nav-tabs > li:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ucd-search .nav-tabs > li {
  border-top-right-radius: 0;
  background: #002542;
}
.ucd-search .nav-tabs > li.active {
  background: #004377;
}
.ucd-search .nav-tabs > li > a {
  padding: 20px;

  color: #ffffff;
}
.ucd-search .nav-tabs > li.active > a,
.ucd-search .nav-tabs > li.active > a:hover,
.ucd-search .nav-tabs > li.active > a:focus {
  color: #ffffff;
}

.ucd-search input {
  font-size: 14px;

  width: 100%;
  padding: 10px;

  border: 0;
  border-radius: 0;
}
.ucd-search input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000;
}
.ucd-search input::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000;
}
.ucd-search input:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000;
}
.ucd-search input:-moz-placeholder {
  /* Firefox 18- */
  color: #000000;
}
.ucd-search button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 15px;

  color: #004377;
  border: 0;
  background: white;
  svg {
    width: 28px;
    height: 28px;
  }
}
.ucd-search--links {
  margin-top: 20px;
  @media all and (min-width: 992px) {
    position: absolute;
    top: 39px;
    right: calc(7.5% + 10px);
  }
  @media all and (min-width: 1200px) {
    right: calc(10% + 10px);
  }
  @media all and (min-width: 1440px) {
    right: calc(15% + 10px);
  }
}
.ucd-search--links ul {
  margin-bottom: 0;
  padding-left: 0;

  list-style: none;

  text-align: left;
  @media all and (min-width: 768px) {
    display: flex;
    align-items: center;
  }
  li {
    margin-bottom: 4px;
    @media all and (min-width: 768px) {
      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }
  a {
    font-size: 16px;
    @media all and (min-width: 768px) {
      //text-decoration: underline;
    }
  }
}
@media all and (min-width: 768px) {
  .ucd-search input {
    font-size: 16px;
  }
}
.ucd-timetable {
  padding-bottom: 30px;
  .section-item {
    @media (min-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .event-slider,
    .slick-list,
    .slick-track {
      height: 100%;
    }
  }
}
// .ucd-timetable {
//     border-top: 4px solid #FFFFFF;
//     border-bottom: 4px solid #FFFFFF;
// }
/* Event slider
*/
.event-slider {
  width: 100%;
}
.event-slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}
.event-slider .slick-dots {
  // bottom: 75px;
  // left: 15px;
  // text-align: left;
  right: 20px;
  bottom: 20px;
  left: auto;

  width: auto;
}
.event-slider .slick-arrow {
  top: auto;
  bottom: -10px;
}
.event-slider .arrow {
  padding: 0.9em;
}
.event-slider .arrow-left {
  left: 0;
}
.event-slider .arrow-right {
  right: auto;
  left: 75px;
}
.event-slider-slide {
  position: relative;

  height: 100%;
  min-height: 360px;
  padding: 0;
  padding-bottom: 30px;

  background-position: center;
  background-size: cover;
}
// .event-slider-slide::before {
//     position: absolute;
//     z-index: 0;
//     top: 0;
//     left: 0;

//     width: 100%;
//     height: 100%;

//     content: "";

//     background: rgba(255, 255, 255, 0);
//     background: -moz-linear-gradient(
//     top,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(51, 51, 51, 0.5) 80%,
//     rgba(0, 0, 0, 1) 100%
//     );
//     background: -webkit-gradient(
//     left top,
//     left bottom,
//     color-stop(0%, rgba(255, 255, 255, 0)),
//     color-stop(80%, rgba(51, 51, 51, 0.5)),
//     color-stop(100%, rgba(0, 0, 0, 1))
//     );
//     background: -webkit-linear-gradient(
//     top,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(51, 51, 51, 0.5) 80%,
//     rgba(0, 0, 0, 1) 100%
//     );
//     background: -o-linear-gradient(
//     top,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(51, 51, 51, 0.5) 80%,
//     rgba(0, 0, 0, 1) 100%
//     );
//     background: -ms-linear-gradient(
//     top,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(51, 51, 51, 0.5) 80%,
//     rgba(0, 0, 0, 1) 100%
//     );
//     background: linear-gradient(
//     to bottom,
//     rgba(255, 255, 255, 0) 0%,
//     rgba(51, 51, 51, 0.5) 80%,
//     rgba(0, 0, 0, 1) 100%
//     );

//     filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000', GradientType=0 );
// }
.event-slider-slide-image {
  position: relative;

  width: 100%;
  max-width: 100px;
}
.event-slider-slide-description {
  position: absolute;
  bottom: 30px;
  left: 30px;

  max-width: 260px;
  margin: 0;
  padding: 30px;

  color: #002542;
  background-color: #fad239;
  // background: rgba(41, 147, 208, 0.9);
  h3,
  a {
    color: #002542;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    line-height: 135%;

    margin-bottom: 15px;
    padding: 0;
  }
  a {
    font-size: 16px;
    font-weight: normal;
    line-height: 165%;
  }
}

/* UCD Flex container
********************/

.ucd-flex {
  padding: 15px;
}
.ucd-flex a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ucd-flex p {
  margin-bottom: 0;

  text-align: left;
  text-transform: capitalize;
}
.ucd-flex img,
.ucd-flex svg {
  width: 75px;
  min-width: 75px;
  height: 75px;
  min-height: 75px;
  margin-right: 15px;
  padding: 15px;
}
.ucd-flex svg,
.ucd-flex svg path {
  fill: #ffffff;
}

.ucd-flex--social {
  display: flex;
  justify-content: center;

  padding: 0;

  // flex-flow: row wrap;
}
.ucd-flex--social a {
  justify-content: center;

  width: 50%;
  padding: 0;

  flex-flow: row wrap;
}
.ucd-flex--quicktasks a {
  transition: 300ms;
  text-decoration: none;

  border: 1px solid transparent;
}
.ucd-flex--social img {
  margin: 0 auto;
}
.ucd-flex--social p {
  width: 100%;

  text-align: center;
}
.ucd-social a {
  margin-right: 15px;
  // padding: 15px;
  margin-left: 15px;

  text-decoration: none;
  @media all and (min-width: 992px) {
    margin-right: 30px;
    margin-left: 30px;
  }
}
.ucd-social a p {
  padding-top: 10px;
}
// @media all and (min-width: 500px) {
//     .ucd-social a {
//         width: 33%;
//     }
// }
@media all and (min-width: 768px) {
  .ucd-flex--quicktasks {
    display: flex;

    flex-flow: row wrap;
  }
  .ucd-flex--quicktasks a {
    width: 50%;
  }
  .ucd-flex--social {
    justify-content: flex-start;
  }
  .ucd-social a {
    /*width: 25%;*/
    // padding: 15px 25px;
  }
}
@media all and (min-width: 992px) {
  .ucd-flex--quicktasks a {
    width: 33%;
    margin-bottom: 15px;
  }
  .ucd-flex--quicktasks a:hover {
    border: 1px solid #ffffff;
  }
  .ucd-flex--social {
    justify-content: center;
  }
  .ucd-social a {
    // padding: 15px 30px;
    transition: all 250ms;
  }
  // .ucd-social a:hover {
  // color: #0A4773;
  // background: #FFFFFF;
  // }
  .ucd-social a .fa {
    transition: all 250ms;
  }
  // .ucd-social a:hover .fa {
  //     color: #0A4773;
  // }
  // .ucd-social a:hover .fa-twitter {
  //     color: #1DA1F2;
  // }
  // .ucd-social a:hover .fa-facebook {
  //     color: #3B5998;
  // }
  // .ucd-social a:hover .fa-instagram {
  //     color: #E1306C;
  // }
  // .ucd-social a:hover .fa-wordpress {
  //     color: #21759B;
  // }
  // .ucd-social a:hover .fa-flickr {
  //     color: #FF0084;
  // }
  // .ucd-social a:hover .fa-youtube-play {
  //     color: #CD201F;
  // }
}
@media all and (min-width: 1200px) {
  .ucd-flex--quicktasks a {
    width: 20%;
    margin-bottom: 20px;
  }
  .ucd-flex--social a {
    width: auto;
  }
}
@media all and (min-width: 1440px) {
  .ucd-social {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  /*.ucd-social a
    {
        padding: 15px 40px;
    }*/
}

/* UCD Quicklinks
****************/
.ucd-quicklinks {
  position: fixed;
  z-index: 1000;
  bottom: 0;

  width: 100%;
  padding: 5px 15px;

  transition: 300ms;

  border-top: 2px solid #008acc;
  /*background: #008acc;*/
  background: #ffffff;
}
/*.ucd-quicklinks iframe
{
  position: fixed;
  top:0;
  width: 100%;
  left: 0;
  transform: translate3d(0,100%,0);
}
.ucd-quicklinks iframe #recv
{
  min-height: 300px;
}
.ucd-quicklinks.chat-active iframe
{
  transform: translate3d(0,-200%,0);
}*/
.ucd-quicklinks iframe {
  display: none;
}
.ucd-quicklinks__wrap {
  display: flex;
  justify-content: space-around;
  /*max-width: 235px;*/

  margin: 0 auto;
}
.ucd-quicklinks__wrap a {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px;

  transition: all 250ms;
  text-decoration: none;

  color: #008acc;
  border-radius: 15px;
  /*width: 85px;*/

  flex: 1 0 auto;
  flex-flow: column nowrap;
  /*background: #fff;*/
  /*box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);*/
}
/*.ucd-quicklinks__wrap a:hover
{
    background: #2993d0;
    box-shadow: 0 10px 20px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23);;
}*/
.ucd-quicklinks__wrap p {
  font-size: 0.8em;
  line-height: 1;

  width: 80px;
  margin-top: 0;
  margin-bottom: 0;

  transition: all 250ms;
  text-align: center;
  text-transform: uppercase;

  color: #0a4773;
}

.ucd-quicklinks-image {
  display: block;

  text-align: center;
}
.ucd-quicklinks-image svg,
.ucd-quicklinks-image img {
  width: 50%;
}
.ucd-quicklinks-image svg {
  max-width: 75px;
  max-height: 75px;

  transition: all 250ms;

  fill: #2993d0;
}
.modal-content iframe {
  width: 100%;
  /* min-height: 200px; */
  min-height: 300px;
}
.chat-modal .modal-header {
  display: none;
}
@media all and (min-width: 768px) {
  .modal-content iframe {
    /* min-height: 300px; */
    min-height: 400px;
  }
}
@media all and (min-width: 992px) {
  .ucd-quicklinks {
    position: absolute;
    z-index: 501;
    top: 238px;
    right: 0;

    display: flex;

    width: auto;
    width: 95px;
    padding-right: 0;

    border-top: 0;
    background: transparent;
  }
  .ucd-quicklinks__wrap {
    justify-content: flex-start;

    flex-flow: column wrap;
  }
  .ucd-quicklinks.chat-active iframe {
    display: block;

    height: 242px;
  }
  .ucd-quicklinks__wrap a:not(:first-child):not(:last-child) {
    border-top: 2px solid #008acc;
    border-bottom: 2px solid #008acc;
    border-radius: 0;
  }
  .ucd-quicklinks__wrap a {
    width: 80px;
    height: 80px;
    padding: 10px;

    cursor: pointer;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    flex: initial;
  }
  .ucd-quicklinks__wrap a:hover {
    text-decoration: none;

    background: #2993d0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .ucd-quicklinks__wrap a:hover svg {
    fill: #ffffff;
  }
  .ucd-quicklinks__wrap a:hover p {
    color: #ffffff;
  }
  .ucd-quicklinks__wrap a:first-child {
    border-bottom-left-radius: 0;
  }
  .ucd-quicklinks__wrap a:last-child {
    border-top-left-radius: 0;
  }
  .ucd-quicklinks-image svg {
    width: 50px;
    max-height: 30px;
  }
}
@media all and (min-width: 1200px) {
  .ucd-quicklinks__wrap {
    padding-bottom: 0;
  }
  .ucd-quicklinks {
    position: fixed;
    z-index: 2;
    top: 50%;

    align-items: center;

    transform: translateY(-50%);
  }
}
/* Footer
********/
footer {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;

  padding: 10px;

  color: #ffffff;
  background: #0a4773;
}
footer .footer-half {
  width: 100%;

  text-align: center;
}
footer .footer-half > img {
  max-width: 50px;
  margin-top: 15px;
  margin-bottom: 15px;
}
footer .footer-half:last-child {
  display: flex;
  justify-content: center;

  flex-flow: row wrap;
}
footer .footer-half:last-child .footer-content:first-child {
  flex: 0 0 100%;
}
.footer-content {
  margin-bottom: 20px;
}
.footer-content ul {
  padding-left: 0;

  list-style: none;
}
.footer-content li {
  font-size: 15px;
  font-weight: normal;
  line-height: 175%;

  margin-bottom: 0;
}
.s-lib-public-body .footer-content li {
  font-size: 14.4px;
}
.footer-content a {
  display: block;

  max-width: 250px;
  margin: 0 auto;
}
.footer-content li a {
  display: inline-block;
}
.footer-content a img {
  max-width: 30px;
}
.footer-half h5,
.footer-content h5 {
  font-family: Roboto Slab;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: 135%;
  /*margin-top: 20px;*/

  margin-bottom: 15px;
}
.footer-content .button {
  margin: 40px auto;
}

@media all and (min-width: 768px) {
  footer {
    display: flex;
    justify-content: space-between;

    padding: 50px 5%;
  }
  footer .footer-half {
    width: 50%;
  }
  .footer-half:last-child {
    display: flex;
    justify-content: space-between;
  }
  footer .footer-half:last-child .footer-content {
    flex: 0 0 100%;
  }
}
@media all and (min-width: 992px) {
  footer {
    padding: 10px 7.5%;
  }
  footer .footer-half {
    text-align: left;
  }
  footer .footer-half:first-child {
    width: 33.3333%;
  }
  footer .footer-half:last-child {
    width: 66.6666%;
  }
  .footer-content .button {
    margin-left: 0;
  }
  footer .footer-half:last-child .footer-content:first-child,
  footer .footer-half:last-child .footer-content {
    width: 33.3333%;
    /*flex: 33.33333%*/

    flex: 1;
  }
  footer .footer-half:last-child .footer-content:not(:last-child) {
    margin-right: 50px;
  }
}
@media all and (min-width: 1200px) {
  footer {
    padding: 25px 10%;
  }
  footer .footer-half:first-child {
    width: 40%;
  }
  footer .footer-half:last-child {
    width: 60%;
  }
}
@media all and (min-width: 1440px) {
  footer {
    padding: 25px 15%;
  }
}

/*        -        *\
\    Miscellaneous  /
 \*       -       */

/* NoScript Styling
******************/

.no-script.modal-backdrop.fade.in {
  position: fixed;
  z-index: 1100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: 0.5;
  background: #000000;
}

.modal.fade.in.no-js {
  position: fixed;
  z-index: 1109;
  top: 0;
  right: 0;
  bottom: auto;
  left: 0;

  display: block;

  padding: 30px;

  text-align: center;

  background: #222222;
}

.modal.fade.in.no-js .noscriptmsg {
  font-size: 1.25em;

  margin-bottom: 20px;

  color: #ffffff;
}
.modal.fade.in.no-js .btn {
  display: block;

  padding: 10px;
}
.modal.fade.in.no-js a.btn {
  margin-bottom: 10px;
}
.modal.fade.in.no-js h6 {
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1.4em;

  margin-top: 10px;

  text-transform: uppercase;

  color: #ffffff;
}
input.modal-closing-trick {
  position: absolute;
  z-index: 1110;
  top: -9999px;
  left: -9999px;

  visibility: hidden;
}

input.modal-closing-trick:checked ~ div {
  display: none !important;
}
/* hide twitter heading */
.timeline-InformationCircle-widgetParent {
  display: none;
}

/***************
LibGuides Styles
***************/
h1.outline,
h2.outline,
h3.outline,
h4.outline,
h5.outline,
h6.outline {
  display: none !important;
}
/* Import header
***************/
#topNav {
  min-height: 45px;

  background: #007eb5;
  -webkit-box-shadow: inset 1px 2px 0 rgba(0, 0, 0, 0.18);
  box-shadow: inset 1px 2px 0 rgba(0, 0, 0, 0.18);
}
#topNav .row {
  margin-right: -34px;
}
#topNav ul {
  margin: 0;
  padding: 0;

  list-style: none;
}
#topNav ul li {
  display: inline;
}
#topNav ul li a {
  font-size: 12px;
  line-height: 22px;

  display: inline-block;

  padding: 10px 8px;

  letter-spacing: -0.1px;

  color: #ffffff;
}
#topNav ul li:first-of-type a {
  padding-left: 0;
}
#topNav .col-sm-9 {
  width: 73%;
  padding-right: 0;
  padding-left: 13px;
}
#topNav .col-sm-3 {
  padding-right: 10px;
  padding-left: 0;
}
#topNav .col-sm-3 ul {
  width: 100%;
}
#topNav .col-sm-3 ul li {
  float: right;
}
#topNav .col-sm-3 ul li .input-group {
  width: 178px;
  height: 28px;
  margin: 10px 0 0 10px;

  border-radius: 0;
}
#topNav .col-sm-3 ul li .input-group .form-control {
  font-size: 12px;

  height: 28px;
  padding: 0 10px;

  border: none;
  border-radius: 0;
}
#topNav .col-sm-3 ul li .input-group .input-group-addon {
  height: 28px;
  padding: 0;

  border: none;
  border-radius: 0;
  background-color: #ffffff;
}
#topNav .col-sm-3 ul li .input-group .input-group-addon input {
  width: 34px;
  height: 27px;

  text-indent: -100px;

  border: none;
  background: url(/t4cms/ucdcollegesandschools_search-icon-blue.png) center 6px
    no-repeat #ffffff;
}
header:not(.navbar-custom) #mainNav li .currentbranch0 a,
header:not(.navbar-custom) #mainNav li a:active,
header:not(.navbar-custom) #mainNav li a:focus,
header:not(.navbar-custom) #mainNav li a:hover {
  text-decoration: none;

  border-bottom: 1px solid #ffffff;
}
#topNav .col-sm-3 ul li a {
  padding: 12px 3px;
}
#topNav .col-sm-3 ul li a span.glyphicon {
  font-size: 14px;

  top: 3px;
}
#topNav .col-sm-3 ul li:last-of-type a {
  padding-right: 5px;
}
header:not(.navbar-custom) {
  background-color: #00427a;
  -webkit-box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 1px 2px 1px 0 rgba(0, 0, 0, 0.15);
}
header:not(.navbar-custom) .col-sm-5 h1 {
  max-width: 405px;
  margin: 26px 0 18px;
}
header:not(.navbar-custom) .col-sm-5 h1 a {
  font-size: 20px;
  line-height: 18px;

  display: block;

  height: 65px;
  padding: 8px 0 0 65px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  color: #ffffff;
  background: url(/t4cms/ucdcollegesandschools_logo.png) no-repeat;

  -webkit-touch-callout: none;
}
header:not(.navbar-custom) .col-sm-5 h1 a span {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;

  display: inline-block;

  width: 100%;

  letter-spacing: -0.93px;
}
header:not(.navbar-custom) .col-sm-5 h1 a span:last-of-type {
  font-weight: 400;

  letter-spacing: -0.9px;
}
footer .col-sm-6 .col-sm-4 h4,
header:not(.navbar-custom) #mainNav li a {
  font-family: 'Roboto Slab', Helvetica, Arial, sans-serif;
}
header:not(.navbar-custom) .col-sm-5 h1 a:focus,
header:not(.navbar-custom) .col-sm-5 h1 a:hover {
  text-decoration: none;
}
header:not(.navbar-custom) nav {
  text-align: right;
}
header:not(.navbar-custom) #mainNav {
  margin: 46px 0 47px;
  padding: 0;

  list-style: none;
}
header:not(.navbar-custom) #mainNav li {
  display: inline;

  padding: 0 14px;
}
header:not(.navbar-custom) #mainNav li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  display: inline-block;

  padding: 0;

  letter-spacing: -0.25px;

  color: #ffffff;
}
header:not(.navbar-custom) #mainNav li .currentbranch0 {
  padding: 0;
}
header:not(.navbar-custom) #mainNav li .currentbranch0 a {
  padding: 0;

  letter-spacing: 0;
}
header:not(.navbar-custom) #mainNav li:last-of-type {
  padding-right: 0;
}
header:not(.navbar-custom) #mainNav li:last-of-type > a {
  letter-spacing: 0;
}
header#school {
  background-color: #3c3c3c;
}
header#school .col-sm-5 h1,
header#school .col-sm-5 h1 a {
  max-width: 100%;
}
header#school .col-sm-5 h1 a span {
  max-width: 100%;

  white-space: nowrap;
}
@media (max-width: 767px) {
  body,
  body #cookie {
    width: 100%;
  }
  #menuIcon,
  body,
  body.active {
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  #menuIcon,
  #searchIcon {
    float: right;

    cursor: pointer;

    color: #ffffff;
  }
  body {
    position: inherit;

    overflow-x: hidden;
  }
  body.active {
    position: fixed;

    -webkit-transform: translateX(-78%);
    transform: translateX(-78%);

    border-right: 1px solid #d8d8d8;
  }
  body.active #cookie {
    width: 178%;
  }
  #menuIcon {
    font-size: 13.56px;
    font-weight: 700;
    line-height: 22px;

    display: block;

    margin: 17px 5px 0 0;
    padding: 10px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transform: scaleY(1.0319);
    transform: scaleY(1.0319);
    text-transform: uppercase;

    -webkit-touch-callout: none;
  }
  #menuIcon.active {
    margin: 17px 4px 0 0;
  }
  #menuIcon.hamburger {
    display: block;
    float: right;

    width: 22px;
    height: 18px;
    margin: 30px 5px 0 0;

    cursor: pointer;

    background: 0 0;
  }
  #searchIcon {
    font-family: 'Glyphicons Halflings';
    font-weight: 400;
    font-style: normal;
    line-height: 1;

    position: relative;

    display: inline-block;

    width: 20px;
    height: 42px;
    margin: 17px 5px 0 0;

    -webkit-font-smoothing: antialiased;
  }
  #mobileMenu ul.main li .currentbranch0,
  #mobileMenu ul.main li a {
    font-size: 16px;
    line-height: 39px;
  }
  #searchIcon:before {
    position: absolute;
    top: 14px;
    left: 5px;

    content: '\e003';
  }
  #mobileMenu {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 100%;

    display: none;
    overflow-y: auto;

    width: 78%;
    padding: 0 15px;

    border-top: 3px solid #008acc;
    border-left: 1px solid #d8d8d8;
    background-color: #eeeeee;
  }
  #mobileMenu.active {
    display: block;
  }
  #mobileMenu .search {
    padding: 21px 25px;

    border-bottom: 1px solid #d8d8d8;
  }
  #mobileMenu .search .input-group {
    border: 1px solid #d8d8d8;
  }
  #mobileMenu .search .input-group .form-control {
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  #mobileMenu .search .input-group .input-group-addon {
    padding: 0;

    border: 0;
    border-radius: 0;
    background-color: #ffffff;
  }
  #mobileMenu .search .input-group .input-group-addon input {
    width: 33px;
    height: 33px;

    text-indent: -100px;

    border: 0;
    border-radius: 0;
    background: url(/t4cms/ucdcollegesandschools_search-icon-black.png) center
      center no-repeat #ffffff;
  }
  #mobileMenu ul,
  #mobileMenu ul.main li {
    border-bottom: 1px solid #d8d8d8;
  }
  #mobileMenu ul {
    margin: 0;
    padding: 10px 0;

    list-style: none;
  }
  #mobileMenu ul li {
    padding: 0 25px;
  }
  #mobileMenu ul li a {
    display: inline-block;

    width: 100%;
  }
  #mobileMenu ul.main {
    padding: 0;
  }
  #mobileMenu ul.main li a {
    font-family: 'Roboto Slab', Helvetica, Arial, sans-serif;
    font-weight: 400;

    color: #0073a4;
  }
  #mobileMenu ul.main li .currentbranch0 a {
    line-height: 1;

    display: inline-block;

    width: auto;

    text-decoration: none;
    letter-spacing: 0;

    color: #00427a;
    border-bottom: 1px solid #00427a;
  }
  #mobileMenu ul.main li:last-of-type {
    border-bottom: none;
  }
  #mobileMenu ul.top li a {
    font-size: 14px;
    line-height: 28px;

    color: #353535;
  }
  #mobileMenu ul.top li:nth-last-of-type(2) {
    padding-bottom: 10px;
  }
  #mobileMenu ul.top li:last-of-type {
    padding-top: 10px;

    border-top: 1px solid #d8d8d8;
  }
  .swap-divs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    -webkit-box-align: start;
    -webkit-box-orient: vertical;
    -ms-flex-align: start;
    -ms-flex-direction: column;
  }
  .swap-divs .left {
    width: 100%;

    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .swap-divs .right {
    width: 100%;

    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .row-eq-height {
    display: block;

    margin-right: -15px;
    margin-left: -15px;
  }
  #breadcrumb,
  #topNav,
  header:not(.navbar-custom) #mainNav,
  header:not(.navbar-custom) nav {
    display: none;
  }
  header:not(.navbar-custom) {
    position: relative;
    z-index: 50;

    width: 100%;

    border-top: 3px solid #008acc;
  }
  header:not(.navbar-custom) .col-sm-5 h1 {
    float: left;

    max-width: 210px;
    margin: 7px 0 13px -3px;
  }
  header:not(.navbar-custom) .col-sm-5 h1 a {
    font-size: 14px;
    line-height: 12px;

    height: auto;
    padding: 0 0 0 44px;

    background: url(/t4cms/ucdcollegesandschools_logo-m.png) no-repeat;
  }
  header:not(.navbar-custom) .col-sm-5 h1 a span {
    font-size: 12px;
    line-height: 13px;

    padding: 1px 0 2px;

    letter-spacing: -0.65px;
  }
  header:not(.navbar-custom) .col-sm-5 h1 a span:last-of-type {
    letter-spacing: -0.7px;
  }
  header#school {
    background-color: #3c3c3c;
  }
  header#school .col-sm-5 h1 {
    max-width: 72%;
  }
  header#school .col-sm-5 h1 a {
    height: auto;
    min-height: 55px;
  }
  header#school .col-sm-5 h1 a span {
    white-space: normal;
  }
}
/*fix*/

#main-nav:before,
#main-nav:after,
.navigation-flex:before,
.navigation-flex:after {
  content: normal !important;
}
@media all and (min-width: 768px) {
  .navbar-offcanvas .navbar-collapse {
    height: unset !important;
    min-height: unset !important;
  }
}

/* Breadcrumb
************/

.breadcrumb {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 0.8em;

  display: none;

  margin-top: 10px;
  margin-bottom: 0;
  padding-bottom: 15px;

  border-bottom: 1px solid #e4e5e5;
  background: #ffffff;
}
.breadcrumb a {
  color: #008acc;
}
@media all and (min-width: 768px) {
  .breadcrumb {
    display: block;
  }
}
@media all and (min-width: 1200px) {
  .breadcrumb {
    padding-left: 5%;
  }
}
@media all and (min-width: 1440px) {
  .breadcrumb {
    padding-left: 10%;
  }
}
@media all and (min-width: 1600px) {
  .breadcrumb {
    padding-left: 15%;
  }
}
/* AZ Index
**********/
/*needs some clean up*/
.page-content h3,
.page-content-libraries h3 {
  font-size: 14px;
  font-weight: normal;

  padding: 10px 0;

  color: #00427a;
}

.alphabet {
  overflow-x: auto;
  overflow-y: hidden;

  width: 100%;
}
.alphabet::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alphabet::-webkit-scrollbar {
  width: 12px;

  background-color: #f5f5f5;
}

.alphabet::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #008acc;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.alphabet,
.alphabet ul {
  clear: both;
}
.alphabet ul {
  display: inline-flex;

  padding-left: 0;

  list-style-type: none;
}
.alphabet li {
  width: 45px;
  height: 45px;
}

.alphabet li + li {
  margin-left: 10px;
}
.alphabet a {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  text-decoration: none;

  color: #008acc;
  border: 1px solid #ebebeb;
  border-radius: 100%;
}
.alphabet .active a {
  font-weight: 600;

  color: #ffffff;
  background: #008acc;
}

/* alphabet a-to-z section */
.letter {
  font-family: 'Roboto Slab', serif;
  font-size: 3em;
  font-weight: 600;
  line-height: 1;
  /*float: left;*/

  margin: 35px 0 25px 0;
  padding: 0;

  color: #0d4974;
}
.letter a {
  color: #0d4974;
}
.definitions {
  float: left;

  width: 100%;
  padding-top: 12px;
}
.definitions a {
  text-transform: none;

  color: #008acc;
}
.ucd-layout-content-inner .definitions h3 {
  font-family: 'Roboto Slab', serif;
  font-size: 1.3em;

  margin-top: 15px;

  color: #0d4974;
}
.definitions p {
  line-height: 1.5em;

  margin-bottom: 0;
  padding-bottom: 15px;

  border-bottom: 1px solid #f5f5f5;
}

@media all and (min-width: 768px) {
  .alphabet ul {
    max-width: 705px;

    flex-flow: row wrap;
  }
  .alphabet li {
    margin-bottom: 10px;
  }

  .alphabet li:nth-child(14) {
    margin-left: 0;
  }
  .alphabet a {
    border-radius: 0;
  }
  .alphabet .active a {
    border-color: #008acc;
  }
}
@media all and (min-width: 992px) {
  .alphabet ul {
    width: 100%;
    max-width: none;
  }
  .alphabet li,
  .alphabet li:nth-child(14),
  .alphabet li + li {
    width: 3.84615385%;
    margin-left: 0;
  }
  .ucd-layout-content-inner .definitions h3 {
    font-size: 1.14em;

    margin-top: 5px;
    padding-bottom: 5px;
  }
  .alphabet .active a::after {
    position: absolute;
  }
  .alphabet .active a::after {
    position: absolute;
    z-index: -1;
    top: 44px;
    left: 0;

    width: 57.7%;
    padding-bottom: 50%;

    content: '';
    transform: rotate(-30deg) skewX(30deg);
    transform-origin: 0 0;

    background-color: #008acc;
  }
}
/*@media all and (min-width: 1200px)
{

}*/
/*@media all and (min-width: 1300px)
{
  .alphabet li,
  .alphabet li:nth-child(14),
  .alphabet li + li
  {
    width: 33px;
  }
}
@media all and (min-width: 1350px)
{
  .alphabet li,
  .alphabet li:nth-child(14),
  .alphabet li + li
  {
    width: 34px;
  }
}*/

/* page-content */
.page-content table,
.panel-body table,
.ucd-layout-content-inner--large table:not(.responsiveTableProcessed) {
  /*overflow-x: scroll;*/
  /*min-width: 500px;*/
  margin-bottom: 5px;
}
.page-content table td,
.page-content table th,
.panel-body table td,
.panel-body table th,
.ucd-layout-content-inner--large table:not(.responsiveTableProcessed) td,
.ucd-layout-content-inner--large table:not(.responsiveTableProcessed) th {
  padding: 15px;

  border: 1px solid #eeeeee;
}
.page-content table ul,
.panel-body table ul,
.ucd-layout-content-inner--large table:not(.responsiveTableProcessed) ul {
  padding-left: 25px;
}
.page-content table li,
.panel-body table li,
.ucd-layout-content-inner--large table:not(.responsiveTableProcessed) li {
  margin-bottom: 5px;
}
.panel-body table p {
  margin-bottom: 0;
}

/*****************************************
   UCD Library inner layout (3 columns) */
/****************************************/

.ucd-layout {
  color: #000000;
}
.ucd-layout__wrapper {
  display: flex;

  flex-flow: column wrap;
}
.ucd-layout-content {
  padding: 15px;
}
.ucd-layout-content h1 {
  font-size: 1.8em;

  color: #0a4773;
}
.ucd-layout h2 {
  margin: 0;
}
.ucd-layout--left {
  width: 100%;

  order: 2;
}
.ucd-layout--right {
  background: #eeeeee;

  order: 1;
}
@media all and (min-width: 992px) {
  .ucd-layout__wrapper {
    flex-flow: row nowrap;
  }
  .ucd-layout--left {
    order: 1;
  }
  .ucd-layout--right {
    padding: 15px;

    order: 2;
  }
}
@media all and (min-width: 1200px) {
  .ucd-layout--left {
    padding-left: 5%;
  }
  .ucd-layout--right {
    padding-right: 5%;
  }
}
@media all and (min-width: 1440px) {
  .ucd-layout--left {
    padding-left: 10%;
  }
  .ucd-layout--right {
    padding-right: 10%;
  }
}
@media all and (min-width: 1600px) {
  .ucd-layout--left {
    padding-left: 15%;
  }
  .ucd-layout--right {
    padding-right: 15%;
  }
}
.ucd-layout-content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 10px;
  padding-bottom: 10px;

  border-bottom: 1px solid #dddddd;

  flex-flow: row nowrap;
}
.ucd-layout-content-title .icons {
  display: flex;
  justify-content: flex-end;

  padding-left: 15px;
}
.ucd-layout-content-title .icons svg {
  width: 25px;
  height: 25px;
  margin-left: 15px;
}
.ucd-layout-content-title .icons .fa {
  font-size: 1.5em;

  padding: 0 10px;

  color: #008acc;
}
.ucd-layout-content-title .icons .share-container {
  display: none;

  transition: 1s;
}
/*.ucd-layout-content-inner .ucd-box-content
{
    margin-top: 15px;
    padding: 15px;
}*/

.ucd-layout-box-content h4,
.ucd-layout-content-inner .has-title .ucd-box-content-title h1 {
  font-family: 'Roboto Slab', serif;
  font-size: 14px;

  position: relative;

  margin: -15px;
  margin-bottom: 0;
  padding: 25px;

  color: #0a4773;
  background: #f5f5f5;
}

.ucd-layout-box-content.title-background-blue h4 {
  color: #ffffff;
  background: #008acc;
}
.ucd-layout-box-content p,
.ucd-box-content-text p {
  margin: 15px 0 10px 0;
  padding-top: 5px;
}
/* if the block has no content : empty*/
.ucd-layout-content-inner:empty {
  display: none;
}
/* if first block has no content, other block doesn't need padding*/
.ucd-layout-content-inner:empty + .ucd-layout-content-inner--large {
  width: 100%;
  padding: 0;
}
.ucd-layout-content-inner {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}
.ucd-layout-content-inner a {
  display: inline-flex;

  padding: 5px 0;

  color: #008acc;
}
.ucd-layout-content-inner h2 {
  font-size: 14px;
  font-weight: 500;

  margin-top: 20px;
  margin-bottom: 20px;

  color: #008acc;
}
.ucd-layout-content-inner h3 {
  color: #008acc;
}
.ucd-layout-content-inner .ucd-box-content-wysiwyg h2 {
  font-family: 'Roboto Slab', serif;

  color: #0a4773;
}

@media all and (min-width: 340px) {
  .ucd-layout-box-content h4,
  .ucd-layout-content-inner .has-title .ucd-box-content-title h1 {
    font-size: 1.14em;
  }
}

@media all and (min-width: 1200px) {
  .ucd-layout-content-inner__wrapper {
    display: flex;
    justify-content: center;
  }
  .ucd-layout-content-title {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .ucd-layout-content-inner:first-child {
    min-width: 275px;

    flex: 0 0 auto;
  }
  .ucd-layout-content-inner--large {
    padding: 0 15px;

    flex: 1;
  }
  .ucd-layout-content-inner h2 {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
@media all and (min-width: 1440px) {
  .ucd-layout-content-inner--large {
    padding: 0 30px;
  }
}
.inner-opening-hours h4 {
  cursor: pointer;
}
.inner-opening-hours h5 {
  font-weight: 500;

  color: #008acc;
}
#opening-hours-library {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;

  display: none;

  padding: 15px;
}
.inner-opening-hours h4::after {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 1.6em;
  font-weight: 100;

  position: absolute;
  top: 50%;
  right: 15px;

  content: '+';
  /*will-change: transform;*/
  transition: all 250ms;
  transform: translateY(-50%);
}
.inner-opening-hours h4.js-open::after {
  transform: translateY(-50%) rotate(945deg);
}
.opening-hours-week,
.opening-hours-weekend,
.opening-hours-times table {
  width: 100%;
}
.opening-hours-times {
  display: flex;
  justify-content: space-between;

  max-width: 250px;

  flex-flow: row wrap;
}
.opening-hours-times:not(:last-child) {
  margin-bottom: 15px;
}
.opening-hours-times td:first-child {
  font-weight: 600;

  width: 50%;
  padding: 5px;
}
.opening-hours-times td:last-child {
  width: 50%;

  text-align: right;
}
@media all and (min-width: 1200px) {
  #opening-hours-library {
    display: block;
  }
  .inner-opening-hours h4::after {
    content: '';
  }
}
.ucd-layout-box-content.no-border {
  padding: 0;

  border: 0;
}
.ucd-layout-box-content {
  margin-top: 15px;
  padding: 15px;
  padding-bottom: 0;

  border: 1px solid #eeeeee;
  border-radius: 0;
}
.ucd-layout-box-content a {
  color: #0089cf;
}
.ucd-layout-box-content iframe {
  width: 100%;
  margin-top: 15px;
}
.ucd-layout-box-content-image {
  height: 175px;

  background-position: center;
  background-size: cover;
}
@media all and (min-width: 768px) {
  .ucd-layout-box-content-image {
    height: 250px;
  }
}
@media all and (min-width: 1440px) {
  .ucd-layout-box-content-image {
    height: 300px;
  }
}

.ucd-layout-navigation__container h4 {
  font-size: 14px;

  position: relative;

  padding: 25px;

  cursor: pointer;
  text-align: left;
  text-transform: uppercase;

  color: #ffffff;
  background: #008acc;
}
.ucd-layout-navigation__container h4::after {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 2em;

  position: absolute;
  top: 50%;
  right: 15px;

  content: '+';
  /*will-change: transform;*/
  transition: all 250ms;
  transform: translateY(-50%);
}
.ucd-layout-navigation__container h4.js-open::after {
  transform: translateY(-50%) rotate(945deg);
}

.ucd-layout-navigation {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;

  display: none;

  background: #ffffff;
}
.ucd-layout-navigation > h1 {
  font-family: 'Roboto Slab', serif;
  font-size: 14px;

  padding: 15px 25px;

  background: #008acc;
}
.ucd-layout-navigation h4 {
  font-size: 1.6em;

  background: #008acc;
}
.ucd-layout-navigation ul {
  padding-left: 0;

  list-style-type: none;
}
.ucd-layout-navigation li a {
  font-size: 0.9em;
  font-weight: 500;

  position: relative;

  display: block;

  padding: 10px 25px;

  color: #008acc;
}
.ucd-layout-navigation li span a {
  font-weight: 600;

  color: #0a4773;
}
.ucd-layout-navigation li span a {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.ucd-layout-navigation li ul {
  display: none;

  background: #f6f6f6;
}

/*.ucd-layout-navigation li span a
{
    padding-left: 50px;
}*/

.ucd-layout-navigation li span {
  position: relative;

  display: block;
}
.ucd-layout-navigation li span + ul {
  display: block;
}
.ucd-layout-navigation li span {
  box-sizing: content-box;

  background: #f5f5f5;
}
.ucd-layout-navigation li span::before {
  position: absolute;
  top: 0;
  left: 0;

  width: 5px;
  height: 100%;

  content: '';

  background: #008acc;
}

.ucd-layout-navigation li.js-hasChildren > a::after,
.ucd-layout-navigation li.js-hasChildren > span > a::after {
  font-family: 'FontAwesome';

  position: absolute;
  top: 50%;
  right: 15px;

  content: '\f105';
  transform: translateY(-50%);
}
.ucd-layout-navigation li.js-hasChildren > span > a::after {
  content: '\f107';
}
.ucd-layout-navigation li.js-hasChildren span {
  background: transparent;
}
.ucd-layout-navigation li.js-hasChildren > span::before {
  width: 0;
}
.ucd-layout-navigation li.js-hasChildren span a {
  padding: 15px 30px;
}
.ucd-layout-navigation li.js-hasChildren li span a,
.ucd-layout-navigation li.js-hasChildren li a {
  padding-left: 50px;
}
@media all and (min-width: 992px) {
  .ucd-layout-navigation__container h4 {
    display: none;
  }
  .ucd-layout-navigation {
    display: block;

    min-width: 250px;
  }
  .ucd-layout-navigation > h1 {
    padding: 15px 30px;
  }
  .ucd-layout-navigation li a {
    padding: 15px 30px;
  }
}
@media all and (min-width: 1200px) {
  .ucd-layout-box-content.js-hours {
    max-width: 320px;
  }
}
/* LibGuides
***********/
.libguides .nav > li > a:hover,
.libguides .nav > li > a:focus {
  outline: none;
}
.libguides {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;

  overflow-x: hidden;

  background: #ffffff;
}
.libguides h1,
.libguides h2,
.libguides h3,
.libguides h4,
.libguides h5,
.libguides h6,
.libguides a,
.libguides .breadcrumb a {
  color: #337ab7;
}
.libguides footer a {
  color: #ffffff;
}
#s-lib-admin-bc .breadcrumb,
#s-lib-bc .breadcrumb {
  padding-bottom: 15px;
}
/*.libguides .btn-default
{
  margin-bottom: 10px;
}*/
.libguides .s-lg-gtitle em {
  font-style: normal;
}
.libguides #s-lib-public-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;

  margin-top: 20px;
  padding-bottom: 10px;

  -webkit-box-align: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
}
.libguides #s-lib-public-header-desc {
  display: block;

  width: 100%;
  margin-top: -12px;
}
.s-lib-searchPage .libguides #s-lib-public-header {
  display: block;
}
.libguides #s-lg-guide-header {
  padding-top: 20px;
}
.libguides #form-group-s-lg-guide-order {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.libguides .s-lib-box {
  margin-bottom: 10px;

  box-shadow: none;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.06), 0 2px 2px rgba(0, 0, 0, 0.12);
}
.libguides #s-lg-hp-nav {
  background: #f5f5f5;
}
.libguides #s-lg-hp-nav-bottom {
  background: #008acc;
}
.libguides #s-lg-hp-nav-bottom span {
  color: #ffffff;
}
.libguides .s-lib-box,
.libguides .s-lib-box-title {
  border: 0;
}
.libguides .s-lib-box-title {
  font-family: 'Roboto Slab', serif;
  font-size: 14px;
  font-weight: 400;

  color: #008acc;
  background: #ffffff;
}
.libguides #s-lg-guide-tabs-title-bar {
  border-color: transparent;
}
.libguides #s-lg-guide-tabs .nav-tabs > li {
  margin: 5px;
}
.libguides .mobile-menu-wrapper .nav-tabs > li {
  width: 100%;
}
.libguides .nav-tabs > li > a {
  margin-right: 0;
}
.libguides #s-lg-tabs-container .nav-tabs > li > a {
  padding: 10px 20px;
}
.libguides #homeSearchInput {
  padding: 10px;
}
.libguides #homeSearchSubmit {
  height: 35px !important;
}
.libguides .s-lib-box-content .ui-tabs-panel.ui-widget-content {
  font-size: 14px;

  padding: 15px;
}
.libguides .s-lib-box-content .popover-content p {
  font-size: 14px;
}

.libguides .ui-widget-header {
  background: #ffffff;
}
.libguides .popover-content {
  padding: 20px;
}
.libguides .ui-state-default,
.libguides .ui-widget-content .ui-state-default,
.libguides .ui-widget-header .ui-state-default {
  border: 0;
  background: #0f0f0f;
}
.libguides .ui-state-active,
.libguides .ui-widget-content .ui-state-active,
.libguides .ui-widget-header .ui-state-active {
  border: 0;
  background: #009bdd;
}
.libguides .s-lib-box-content .ui-tabs-nav li > a {
  color: #ffffff;
}
.libguides .s-lib-box-content .ui-state-default .ui-tabs-nav li > a {
  color: #009bdd;
}
.libguides .ui-tabs .ui-tabs-nav li a {
  font-size: 14px;

  padding: 14px;
}
.libguides .s-lib-box-content {
  padding: 15px;
  /*background: #f7f7f7*/

  background: #f9f9f9;
}
.libguides .s-lib-box-content ul {
  margin: 0;
}
.libguides .s-lib-box-content ul.s-lg-system-list {
  margin: 5px 0;
}
.libguides .s-lib-box-content li {
  font-size: 14px;
}
.libguides .s-lib-box-content li {
  margin-bottom: 5px;
}
.libguides .s-lib-box-content img {
  width: auto;
  height: auto;
  margin: 0;
  padding: 5px;

  box-shadow: none;
}
.libguides .s-lib-box-content p {
  font-size: 14px;
  line-height: 1.6;

  padding-bottom: 10px;
}
.libguides .s-lib-box-content #summonSearchContainer p {
  font-size: 12px;
}
/*.libguides #s-lg-tabs-container .nav-tabs > li > button.s-lg-tab-top-link:hover,
.libguides #s-lg-tabs-container .nav-tabs > li > a.s-lg-tab-top-link:hover,
.libguides #s-lg-tabs-container .nav-tabs > li > a.s-lg-tab-drop:hover
{
    padding: 10px 20px;
}*/

/* =========================================== */
/* bootstrap overrides */
/* =========================================== */
.s-lg-guide-body #s-lg-tabs-container .nav-tabs > li > a,
.s-lg-guide-body #s-lg-tabs-container .nav-tabs > li > button,
.s-lg-guide-body #s-lg-tabs-container .nav-pills > li > a,
.s-lg-guide-body #s-lg-tabs-container .nav-pills > li > button,
.libguides .mobile-menu-wrapper .nav-pills > li > a,
.libguides .mobile-menu-wrapper .nav-pills > li > button,
.libguides .mobile-menu-wrapper .nav-tabs > li > a,
.libguides .mobile-menu-wrapper .nav-tabs > li > button {
  font-size: 14px;
  /*font-weight: bold;*/
  font-weight: normal;

  padding: 15px 10px;

  color: #007eb5;
  border: 0;
  border-radius: 0;
  background-color: #ffffff;
}
.s-lg-guide-body #s-lg-tabs-container .nav-tabs > li.open > a .caret,
.s-lg-guide-body #s-lg-tabs-container .nav-tabs > li.open > button .caret,
.s-lg-guide-body #s-lg-tabs-container .nav-pills > li.open > a .caret,
.s-lg-guide-body #s-lg-tabs-container .nav-pills > li.open > button .caret,
.libguides .mobile-menu-wrapper .nav-pills > li.open > a .caret,
.libguides .mobile-menu-wrapper .nav-pills > li.open > button .caret,
.libguides .mobile-menu-wrapper .nav-tabs > li.open > a .caret,
.libguides .mobile-menu-wrapper .nav-tabs > li.open > button .caret {
  transform: rotate(180deg);
}
#s-lg-tabs-container .nav-tabs {
  border-bottom: 0;
}
#s-lg-tabs-container .nav-tabs > li#s-lg-admin-tab-add > a {
  border-radius: 4px 4px 0 0;
}
/*#s-lg-tabs-container .nav-tabs > .active > a,
#s-lg-tabs-container .nav-tabs > .active > button,*/
.s-lg-guide-body #s-lg-tabs-container .nav-pills > .active > a,
.s-lg-guide-body #s-lg-tabs-container .nav-pills > .active > button,
.s-lg-guide-body .mobile-menu-wrapper .nav-pills > .active > a,
.s-lg-guide-body .mobile-menu-wrapper .nav-pills > .active > button {
  font-weight: bold;

  padding: 15px 30px;

  cursor: default;

  color: #00427a;
  border: 0;
  border-left: 5px solid #008acc;
  border-radius: 0;
  /*border-bottom-color: transparent;*/
  background-color: #f7f7f7;
}
#s-lg-tabs-container .nav-tabs > .active > .s-lg-subtab-ul > .active > a,
#s-lg-tabs-container .nav-tabs > .active > .s-lg-subtab-ul > .active > button {
  cursor: default;

  color: #efefef;
  border-bottom-color: transparent;
  background-color: #009bdd;
}
/*#s-lg-tabs-container .nav-tabs > .active > a:hover,
#s-lg-tabs-container .nav-pills > .active > a:hover,*/
#s-lg-tabs-container .nav-tabs > .active > button:hover,
#s-lg-tabs-container .nav-pills > .active > button:hover,
.mobile-menu-wrapper .nav-pills > .active > button:hover {
  font-weight: bold;

  cursor: pointer;

  color: #efefef;
  border: 1px solid transparent;
  border-bottom-color: transparent;
  border-radius: 0;
  background-color: #009bdd;
}
#s-lg-tabs-container .nav .dropdown-toggle .caret {
  border-top-color: #008acc;
}
#s-lg-tabs-container .nav .active .dropdown-toggle .caret {
  border-top-color: #ffffff;
}
#s-lg-tabs-container .nav-tabs button.dropdown-toggle .caret {
  margin-top: -3px;
  margin-left: 2px;
}
#s-lg-tabs-container .nav-tabs > li > a.s-lg-tab-drop,
#s-lg-tabs-container .nav-tabs > li > button.s-lg-tab-drop {
  position: absolute;
  top: calc(50% - 2px);
  right: 0;

  width: auto;
  margin-right: 2px;
  padding: 0 6px 0 3px;

  transform: translateY(-50%);

  border-left: 1px solid transparent;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
/*#s-lg-tabs-container .nav-tabs > li > a.s-lg-tab-drop:hover,
#s-lg-tabs-container .nav-tabs > li > button.s-lg-tab-drop:hover
{
    padding: 4px 6px 4px 3px;

    border-left: 1px solid #bbb;
    border-radius: 0;
}*/
/*#s-lg-tabs-container .nav-tabs > li > a.s-lg-tab-top-link,
#s-lg-tabs-container .nav-tabs > li > a.s-lg-tab-top-link:hover,
#s-lg-tabs-container .nav-tabs > li > button.s-lg-tab-top-link,
#s-lg-tabs-container .nav-tabs > li > button.s-lg-tab-top-link:hover
{
    font-weight: bold;

    padding: 4px 5px 4px 10px;

    border-radius: 0;
}*/
.libguides #s-lg-tabs-container .nav-tabs > li > a.s-lg-tab-top-link,
.libguides #s-lg-tabs-container .nav-tabs > li > button.s-lg-tab-top-link {
  margin-right: 0;
  padding-right: 50px;
}
#s-lg-tabs-container .nav-pills > li > a.s-lg-tab-drop,
#s-lg-tabs-container .nav-pills > li > button.s-lg-tab-drop {
  position: absolute;
  right: 0;

  padding: 15px 30px;

  border-left: 1px solid transparent;
  border-radius: 0;
}
#s-lg-tabs-container .nav-pills > li > a.s-lg-tab-drop:hover,
#s-lg-tabs-container .nav-pills > li > button.s-lg-tab-drop:hover {
  padding: 15px 30px;

  border-left: 0;
  border-radius: 0;
}
#s-lg-tabs-container .nav-pills > li > a.s-lg-tab-top-link,
#s-lg-tabs-container .nav-pills > li > a.s-lg-tab-top-link:hover,
#s-lg-tabs-container .nav-pills > li > button.s-lg-tab-top-link,
#s-lg-tabs-container .nav-pills > li > button.s-lg-tab-top-link:hover {
  /*font-weight: bold;*/
  float: left;

  width: 100%;
  padding: 15px 30px;

  border-radius: 0;
}
.s-lib-box {
  border-width: 3px;
  border-color: #01427a;
  border-radius: 0;
  background-color: #efefef;
  box-shadow: 0 8px 6px -6px #aaaaaa;
}
.s-lib-box-std .s-lib-box-title {
  color: #e7f1f5;
  border-bottom: 3px solid #01427a;
  background-color: #01427a;
  background-image: none;
}
.s-lib-box .s-lib-box-title {
  color: #e7f1f5;
  border-bottom: 3px solid #01427a;
  border-radius: 0;
  background-color: #01427a;
  background-image: none;
}
.s-lib-box .s-lg-box-footer {
  border-radius: 0;
}

/* libguides landing section
*/

#s-lg-hp-nav .nav-pills {
  display: flex;
  overflow-x: auto;

  margin: 0 10px 25px 0;
  padding-left: 0;

  list-style-type: none;

  text-align: left;

  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
/*@media all and (max-width: 767px) {*/
#s-lg-hp-nav .nav-pills::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#s-lg-hp-nav .nav-pills::-webkit-scrollbar {
  width: 12px;

  background-color: #f5f5f5;
}

#s-lg-hp-nav .nav-pills::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #008acc;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
/*}*/
#s-lg-hp-nav .nav-pills > li > a,
#s-lg-hp-nav .nav-pills > li > button {
  font-size: 14px;
  /*font-weight: bold;*/
  font-weight: normal;

  padding: 10px 15px;

  color: #007eb5;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: #ffffff;
}

#s-lg-hp-nav .nav-pills > li > a,
#s-lg-hp-nav .nav-pills > li > button {
  font-size: 14px;
  font-weight: normal;

  display: inline-block;

  width: 100%;
  padding: 10px 15px;

  white-space: nowrap;
  text-decoration: none;

  color: #008acc;
  border: 1px solid transparent;
  border-radius: 25px;
  background: #eeeeee;
}
#s-lg-hp-nav .nav-pills > li.active > a,
#s-lg-hp-nav .nav-pills > li.active > button {
  font-weight: bold;
  /*color: #efefef;*/

  cursor: default;

  color: #ffffff;
  /*background-color: #009bdd;*/
  border: 1px solid transparent;
  /*-webkit-border-radius: 0;
-moz-border-radius: 0;
border-radius: 0;*/
  border-bottom-color: transparent;
  background: #008acc;
}
#s-lg-hp-nav .nav-pills li {
  position: relative;

  margin: 5px 5px 15px 5px;

  flex: 0 0 auto;
}
#s-lg-hp-nav .nav-pills > li {
  margin: 5px 5px 15px 5px;
}
#s-lg-hp-nav .nav-pills li:first-child {
  margin-left: 0;
}
#s-lg-hp-nav .nav-pills > li > a {
  padding: 10px 20px;
}

/* Libguides controls (order filter)
*/
#s-lg-guide-list-controls {
  margin-bottom: 10px;
}
#s-lg-guide-list-controls .pull-left,
#s-lg-guide-list-controls .pull-right {
  float: none !important;
}
#s-lg-guide-list-controls #s-lg-guide-order {
  width: 100%;
}
#s-lg-guide-list-controls .form-group {
  width: 100%;
}
/*@media all and (max-width:767px)
{
  #s-lg-guide-tabs ul.nav li.dropdown a.s-lg-tab-drop:hover ~ ul.dropdown-menu,
  #s-lg-guide-tabs ul.nav li.dropdown ul.dropdown-menu:hover,
  #s-lg-guide-tabs ul.nav li.dropdown:hover ul.dropdown-menu
  {
    display: none;
  }
}*/
#s-lg-tabs-container .nav-tabs > li > a.s-lg-tab-top-link,
#s-lg-tabs-container .nav-tabs > li > a.s-lg-tab-top-link:hover,
#s-lg-tabs-container .nav-tabs > li > button.s-lg-tab-top-link,
#s-lg-tabs-container .nav-tabs > li > button.s-lg-tab-top-link:hover {
  font-weight: normal;

  padding: 5px 10px;
  padding-right: 50px;

  border-radius: 0;
}
#s-lg-guide-tabs .dropdown-menu {
  overflow-y: auto;
  /*background: #fff;*/

  max-height: 500px;

  border-top: 1px solid #f8f8f8;
  border-radius: 5px;
}
@media all and (min-width: 768px) {
  #s-lg-guide-list-controls {
    margin-bottom: 25px;
  }
}

/* Libguides index list
*/

#s-lg-index-list .panel-heading {
  padding: 15px;

  background: #f4f4f4;
}
#s-lg-index-list .panel-body {
  background: #f8f8f8;
}
#s-lg-index-list .panel-heading a,
#s-lg-index-list .s-lg-gtitle {
  font-size: 1.3em;
  font-weight: 300;
}

/*
*/

.libguides .ui-widget-content.ui-tabs-panel {
  border: 0;
  border-top: 1px solid #eeeeee;
}
.libguides ul.ui-tabs-nav {
  padding: 10px;
  /*padding-left: 0;*/

  list-style-type: none;
}
.libguides ul.ui-tabs-nav li {
  padding: 0;

  background: #fefefe;
}
.libguides ul.ui-tabs-nav li a.ui-tabs-anchor {
  padding: 0;
}
.libguides ul.ui-tabs-nav.ui-widget-header li {
  padding: 10px 20px;
  /*border-radius: 25px;*/

  background: #f0f0f0;
}

.libguides ul.ui-tabs-nav.ui-widget-header li a {
  color: #008acc;
}
.libguides ul.ui-tabs-nav.ui-widget-header li.ui-state-active {
  background: #008acc;
}
.libguides ul.ui-tabs-nav.ui-widget-header li.ui-state-active a {
  color: #ffffff;
}
@media all and (min-width: 768px) {
  .libguides ul.ui-tabs-nav.ui-widget-header {
    display: flex;

    margin: 5px;

    flex-wrap: wrap;
  }
  .libguides ul.ui-tabs-nav.ui-widget-header li {
    margin-right: 5px;
    padding: 10px 15px;
  }
}
/* Effective changes
*******************/

/* breadcrumbs */
.libguides #s-lib-bc {
  display: none;

  padding: 15px 0;
}
.libguides #s-lib-bc a {
  font-weight: normal;

  color: #008acc;
}
@media all and (min-width: 768px) {
  .libguides #s-lib-bc {
    display: block;

    width: 100%;
  }
}

/* page title */

#s-lg-guide-header-info h1,
.s-lib-header h1 {
  font-family: 'Roboto Slab', Helvetica, Arial, sans-serif;
  font-size: 1.8em;
  font-weight: 300;

  color: #00427a;
}
.libguides #s-lg-guide-header-info::before {
  display: table;
  clear: both;

  content: '';
}
@media all and (min-width: 768px) {
  #s-lg-guide-header-info h1,
  .s-lib-header h1 {
    font-size: 2.2em;
  }
}
@media all and (min-width: 992px) {
  #s-lg-guide-header-info {
    float: left;

    width: 75%;
  }
  #s-lg-guide-header-info h1 {
    margin-top: 0;
    padding-top: 0;
  }
  #s-lg-guide-header > .pull-right {
    width: 20%;
  }
  #s-lg-guide-header #s-lg-guide-header-search {
    width: 100%;
  }
}
/* tabs container (navigation rhs) */
#s-lg-tabs-container {
  padding: 15px;

  background: #eeeeee;
}

.libguides .s-lib-box .s-lib-box-title {
  font-size: 1.4em;

  padding: 15px;

  background: #008acc;
}

/* tabs container (navigation horizontal) */
/* NOTE: pills are tabs and tabs are pills */

#s-lg-tabs-container .nav-tabs {
  display: block;
  /*overflow-x: scroll;*/
  /*overflow-y: initial;*/
  /*flex-wrap: wrap;*/
  /*margin: 25px 10px 25px 0;*/

  padding-left: 0;

  list-style-type: none;

  text-align: left;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
#s-lg-tabs-container .nav-tabs[role='menu'] {
  display: block;
}

#s-lg-tabs-container .nav-tabs::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#s-lg-tabs-container .nav-tabs::-webkit-scrollbar {
  width: 12px;

  background-color: #f5f5f5;
}

#s-lg-tabs-container .nav-tabs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #008acc;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
#s-lg-tabs-container .nav-tabs li {
  position: relative;

  margin: 5px 5px 15px 5px;

  flex: 0 0 auto;
}
/*#s-lg-tabs-container .nav-tabs li:first-child
{
    margin-left: 0;
}*/
#s-lg-tabs-container .nav-tabs > li > a {
  font-size: 14px;

  display: inline-block;

  width: 100%;
  padding: 10px 15px;

  white-space: nowrap;
  text-decoration: none;

  color: #008acc;
  /*border-radius: 25px;*/
  /*border: 1px solid #eee;*/
  background: #ffffff;
}
#s-lg-tabs-container .nav-tabs > li.active > a {
  color: #ffffff;
  background: #008acc;
}
@media all and (min-width: 992px) {
  #s-lg-tabs-container {
    padding: 15px;

    background: #eeeeee;
  }
  #s-lg-tabs-container .nav-tabs {
    display: block;
    overflow: initial;
  }
  .libguides #s-lg-guide-tabs .nav-tabs > li {
    margin: 5px;
    margin-left: 0;
  }
  #s-lg-tabs-container .nav-tabs li {
    flex: 1;
  }
  #s-lg-tabs-container .nav-tabs > li > a {
    border-radius: 0;
  }
  .libguides #s-lg-tabs-container .nav-tabs > li > a {
    padding: 5px 10px;

    white-space: normal;
  }

  .s-lg-guide-body #s-lg-tabs-container .nav-tabs > li > a.s-lg-tab-drop:hover,
  .s-lg-guide-body
    #s-lg-tabs-container
    .nav-tabs
    > .active
    > .s-lg-tab-drop.dropdown-toggle,
  .libguides #s-lg-tabs-container .nav-tabs > li > .s-lg-tab-drop {
    padding: 0 15px;

    border: 0;
  }
  .libguides
    #s-lg-tabs-container
    .nav-tabs
    > li
    > a:hover
    + .dropdown-toggle
    .caret,
  .libguides
    #s-lg-tabs-container
    .nav-tabs
    > li
    > a.s-lg-tab-drop:hover
    .caret {
    transform: rotate(180deg);
  }
}
/*@media all and (min-width: 768px)
{
  .libguides .s-lib-box .s-lib-box-title,
  .libguides .s-lib-box-content
  {
    padding: 20px;
  }
}*/

/*profile*/
.s-lib-profile-div {
  margin-bottom: 0;
}
.s-lib-profile-div a {
  display: block;
}
.s-lib-profile-subjects a {
  display: inline-block;
}
.s-lib-profile-name {
  font-size: 1.5em;

  padding: 0 15px 15px;
}
.s-lib-profile-email {
  margin-top: 10px;
  margin-bottom: 10px;
}
.s-lib-profile-email a {
  font-size: 10px;

  color: #ffffff;
  background: #008acc;
}
/*gallery*/

.ucd-box-content-gallery {
  display: flex;

  flex-flow: row wrap;
}
.ucd-box-content-gallery-image {
  width: 100%;
  height: 150px;
  margin-bottom: 10px;

  background-position: center;
  background-size: cover;
}
.ucd-box-content-gallery-btn {
  width: 100%;
  margin-top: 15px;

  text-align: center;
}
a.button--gallery {
  color: #00427a;
  border: 1px solid #00427a;
  background: transparent;
}
@media all and (min-width: 768px) {
  .ucd-box-content-gallery-image {
    height: 200px;

    flex: 1;
  }
  .ucd-box-content-gallery-image + .ucd-box-content-gallery-image {
    margin-left: 5px;
  }
}
.ucd-layout-content-inner.ucd-layout-content-inner--large > h1 {
  display: none;
}
.s-lib-box-content .s-lib-profile-image img {
  max-width: 200px;
}

/* Mobile menu wrapper
*********************/
.mobile-menu-wrapper h2 {
  font-size: 14px;

  position: relative;

  margin-top: 15px;
  padding: 15px;

  cursor: pointer;
  text-transform: uppercase;

  color: #ffffff;
  border: 1px solid #eeeeee;
  background: #008acc;
}
.mobile-menu-wrapper h2::after {
  font-family: 'FontAwesome';
  font-size: 2em;

  position: absolute;
  top: 50%;
  right: 25px;

  content: '+';
  transition: 300ms;
  transform: translateY(-50%) rotate(0);

  color: #ffffff;

  will-change: transform;
}
/*.mobile-menu-wrapper ul[role='menu']
{
    display: none!important;
}
.mobile-menu-wrapper.js-active ul[role='menu']
{
    display: block!important
}*/
.mobile-menu-wrapper.js-active h2::after {
  transform: translateY(-50%) rotate(45deg);
}
#s-lg-guide-header .mobile-menu-wrapper {
  float: left;

  width: 100%;
}

/* More libguides
****************/

/*

Direct edits to the core theme (try not to)

*/

.s-lg-title a {
  display: block;
}
.s-lg-guide-body .nav-stacked > li + li {
  margin-top: 0;
}

#s-lg-tabs-container .nav-pills > li > a.s-lg-tab-top-link,
#s-lg-tabs-container .nav-pills > li > a.s-lg-tab-top-link:hover,
#s-lg-tabs-container .nav-pills > li > button.s-lg-tab-top-link,
#s-lg-tabs-container .nav-pills > li > button.s-lg-tab-top-link:hover {
  font-weight: normal;
}

.s-lg-guide-body #s-lg-tabs-container .nav-tabs > .active > a:hover,
.s-lg-guide-body #s-lg-tabs-container .nav-pills > .active > a:hover,
.s-lg-guide-body #s-lg-tabs-container .nav-tabs > .active > button:hover,
.s-lg-guide-body #s-lg-tabs-container .nav-pills > .active > button:hover {
  font-weight: normal;

  border: 0;
}

.dropdown-menu,
#s-lg-guide-tabs ul.nav li.dropdown a.s-lg-tab-drop:hover ~ ul.dropdown-menu,
#s-lg-guide-tabs ul.nav li.dropdown ul.dropdown-menu:hover,
#s-lg-guide-tabs ul.nav li.dropdown:hover ul.dropdown-menu {
  width: auto;
  min-width: 100%;

  border: 0;
  border-radius: 0;
  background: #007eb5;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.13);
}
.split-button-nav .dropdown-menu {
  width: 100%;
}
.dropdown-menu > li > a {
  font-size: 14px;

  padding: 5px 10px;

  color: #ffffff;
}
.s-lg-guide-body .dropdown-toggle {
  position: absolute;
  top: 0;
  right: 0;
}
#s-lg-guide-description {
  font-size: 14px;
}
#s-lg-guide-desc-container {
  margin-bottom: 15px;
}

.s-lib-public-body .btn-info {
  color: #ffffff;
  border-radius: 0;
  background: #337ab7;
}

#s-lg-guide-search-box {
  width: 100%;
  margin-left: 0;
}
#s-lg-guide-search-box input,
#s-lg-guide-search-box button {
  border-radius: 0;
}

#s-lg-profile-az-div {
  display: none;
}
/* prevent overflow due to .row */
@media all and (min-width: 992px) and (max-width: 1199px) {
  .container {
    width: 100%;
  }
}

/* cover images overwrite */
.libguides .s-lib-box-content img.s-lg-book-cover-img-0 {
  width: 90px;
}

.libguides .s-lib-box-content img.s-lg-book-cover-img-1 {
  width: 140px;
}

.libguides .s-lib-box-content img.s-lg-book-cover-img-2 {
  width: 200px;
}

/* Print stylesheet
******************/

@media print {
  footer,
  .message,
  .icons,
  .ucd-quicklinks,
  .ucd-layout-box-content {
    display: none;
  }
  .ucd-layout-content-inner .ucd-layout-box-content:first-child,
  #opening-hours-library {
    display: block;
  }
}

.cookie-notice {
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 10px 0;

  transition: 0.5s ease;

  color: #007eb5;
  background: #f1eff0;
}
.cookie-notice p {
  font-size: 12px;
  line-height: 1.55;

  margin: 0;
}
@media all and (min-width: 768px) {
  .cookie-notice p {
    font-size: 14px;

    text-align: center;
  }
}
.cookie-notice .links {
  display: block;

  margin-top: 5px;
}
@media all and (max-width: 767px) {
  .cookie-notice .links {
    display: inline;

    margin-top: 0;
  }
}
.cookie-notice a {
  font-weight: 800;

  display: inline-block;

  margin: 0 5px;

  text-decoration: underline;

  color: #007eb5;
}
@media all and (max-width: 767px) {
  .cookie-notice a:first-of-type {
    margin-left: 0;
  }
}
@media all and (max-width: 767px) {
  .cookie-notice a:first-of-type {
    margin-left: 10px;
  }
}
.ucd-boxes {
  padding-top: 0;
}
.ucd-boxes__container {
  display: flex;
  justify-content: space-between;

  width: 100%;

  flex-wrap: wrap;
}
.ucd-box {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.35;
  width: 100%;

  @media all and (min-width: 768px) {
    width: calc(50% - 5px);
  }
  @media all and (min-width: 992px) {
    width: calc(100% / 3 - 15px);
    margin-bottom: 30px;
    padding: 30px;
    height: 230px;
  }
  padding: 15px;
  margin-bottom: 10px;

  text-decoration: none;

  color: white;
  background: #008acc;
  &:hover {
    text-decoration: none;

    color: white;
  }
  span {
    display: block;
  }
  img {
    width: auto;
  }
}
.ucd-box__title {
  margin-top: 15px;
  margin-bottom: 20px;
}
.ucd-box__icon {
  font-size: 48px;
  line-height: 1;
}
.ucd-box--green-light {
  color: #002542;
  background-color: #6bbe51;
  &:hover {
    color: #002542;
  }
  .ucd-box__arrow {
    svg {
      path {
        fill: #002542;
      }
    }
  }
}
.ucd-box__widget {
  text-align: center;
  .occupancy-gage {
    svg {
      max-width: 225px;
    }
  }
}
.ucd-box--widget {
  color: #008acc;
  background-color: #ffffff;
  @media all and (min-width: 992px) {
    padding-bottom: 20px;
    padding-top: 28px;
  }
  &:hover {
    color: #008acc;
  }
  .ucd-box__title {
    @media all and (min-width: 768px) {
      margin-top: 5px;
    }
    span:first-child {
      font-size: 18px;
      font-weight: 700;
      @media all and (min-width: 1440px) {
        font-size: 20px;
      }
    }
    span:nth-child(2) {
      font-size: 14px;
      font-weight: 700;

      margin-top: 5px;
    }
  }
}
.ucd-box--green {
  background-color: #009852;
}
.ucd-box--navy {
  background-color: #004377;
}
.ucd-box--blue {
  background-color: #008acc;
}
.ucd-box--image {
  position: relative;

  display: flex;
  align-items: flex-end;

  background-color: #f2f2f2;
  background-size: cover;
  &:hover {
    color: white;
  }
  &::before {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    content: '';

    background: linear-gradient(
      190.17deg,
      rgba(0, 138, 204, 0.2) 7.7%,
      rgba(0, 37, 66, 0) 27.16%,
      #00060b 85.95%
    );
  }
  .ucd-box__title {
    font-size: 24px;
    font-weight: bold;
    line-height: 135%;

    position: relative;

    margin-bottom: 0;
    svg {
      margin-left: 10px;
    }
  }
}

.ucd-quicktasks__wrap,
.ucd-quicktask {
  border: 1px solid white;
}
.ucd-quicktasks__wrap {
  display: flex;
  @media all and (max-width: 991px) {
    flex-wrap: wrap;
  }
  // flex-wrap:wrap;
  border-right: none;
  border-bottom: none;
  overflow: hidden;
}
.ucd-quicktask {
  display: flex;
  align-items: center;

  width: 50%;
  padding: 20px 15px;

  text-decoration: none;
  border-collapse: collapse;
  // border: 1px solid white;
  // border-bottom: 1px solid white;
  border-top: none;
  border-left: none;

  @media all and (min-width: 1200px) {
    padding: 30px 25px;
    width: 100%;
    flex-basis: 25%;
  }
  @media all and (max-width: 1199px) {
    flex-direction: column;
    flex-basis: 50%;
  }
  & + & {
    // border-left: 1px solid white;
  }
  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 135%;

    width: auto;
    margin-bottom: 0;
    @media all and (max-width: 1199px) {
      text-align: center;
      margin-top: 20px;
      font-size: 14px;
      font-weight: normal;
    }
    @media all and (min-width: 1200px) {
      font-size: 18px;
      margin-left: 20px;
    }
  }
  &:hover {
    text-decoration: none;
  }
}
.ucd-quicktask__icon {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 60px;
  min-height: 60px;

  border: 2px solid white;
  border-radius: 50%;
  i {
    font-size: 36px;
  }
}
svg.ucd-quicktask__icon {
  width: 60px;
}
.button--footer {
  font-family: Roboto Slab;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 145%;

  margin-top: 50px;

  color: #002542;
  background-color: #fad239;
  &:hover {
    color: #002542;
    background-color: #fad239;
  }
}

/* Chat Widget */
.s-lch-widget-float {
  iframe {
    max-height: 50%;
  }
}
